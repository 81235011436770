import { ReactElement } from 'react';
import { ISiteSummaryCard } from '.';
import { NavLink, useNavigate } from 'react-router-dom';
import { useContractor360Store } from '@store/contractor360Store';
import { getSiteConversionStatus, siteVisitedLabel } from '@components/siteSummary/components';

export const SiteSummaryCard = (props: ISiteSummaryCard): ReactElement => {
	const { site } = props;
	const { setSiteStatusModal } = useContractor360Store();
	const navigate = useNavigate();

	const handleSiteStatusChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
		e.preventDefault();
		setSiteStatusModal(true, site?.site_lead_status, site?.id);
	};
	const navigateToDetail = (): void => {
		navigate('/site-lead-overview', {
			state: {
				siteId: site?.id,
			},
		});
	};

	return (
		<li className="d-col d-col-2">
			<div className="summary-info-main">
				<div
					className="summary-info-upper cursor-pointer"
					aria-hidden={true}
					title={site?.name ?? ''}
					aria-label={site?.name ?? ''}
					onClick={navigateToDetail}
				>
					<div className="row">
						<div className="d-col d-col-5 summary-info-title">
							<address className="h3" title={site?.site_location_c ?? ''}>
								{site?.name}
							</address>
							<span className="h5">ID-{site?.sitenumber_c}</span>
						</div>
						<div className="d-col d-col-7 summary-info-label">
							<ul>
								{site?.reason_of_competition && (
									<li>
										<span className="label-warning h5">{site?.reason_of_competition}</span>
									</li>
								)}
								{site?.current_stage_of_site_c && (
									<li>
										{/*<span className="label-info h5">{site?.current_stage_of_site_c}</span>*/}
										{siteVisitedLabel(site.current_stage_of_site_c, 'label-info')}
									</li>
								)}
							</ul>
						</div>
					</div>
				</div>
				<div className="summary-info-bottom">
					<p className="h5">
						Contractor Name: <span>{site?.contract_name ?? '-'}</span>
					</p>
					<NavLink to="/" className="close-site" onClick={handleSiteStatusChange}>
						<span
							title={!site?.site_lead_status ? 'Site Not Converted' : `Site Converted to ${site?.site_lead_status}`}
							className="h5"
						>
							{getSiteConversionStatus(site?.site_lead_status)}
						</span>
					</NavLink>
				</div>
			</div>
		</li>
	);
};
