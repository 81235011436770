import { ReactElement } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ISiteSummaryCardProps } from '@components/siteSummary';
import { getSiteConversionStatus, siteVisitedLabel } from '@components/siteSummary/components';
import { convertISODateTODDMMYY } from '@helper/utils';
import { useContractor360Store } from '@store/contractor360Store';

export const SiteSummaryCard = (props: ISiteSummaryCardProps): ReactElement => {
	const { site } = props;
	const { setSiteStatusModal } = useContractor360Store();
	const navigate = useNavigate();

	const handleSiteStatusChange = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
		e.preventDefault();
		setSiteStatusModal(true, site?.site_lead_status, site?.id);
	};

	const navigateToDetail = (): void => {
		navigate('/site-lead-overview', {
			state: {
				siteId: site?.id,
			},
		});
	};

	return (
		<li className="d-col d-col-2">
			<div className="summary-card-info" id="site-summary-card">
				<div className="summary-details-upper">
					<div
						className="left-content cursor-pointer"
						aria-hidden={true}
						title={site?.name}
						aria-label={site?.name}
						onClick={navigateToDetail}
					>
						<address className="h3" title={site?.name}>
							{site?.name}
						</address>
						<span className="h5 user-id">ID-{site?.sitenumber_c}</span>
					</div>

					<div className="right-content">
						{site?.competition_site && <span className="site-label h5">Competition Site</span>}
						{siteVisitedLabel(site.current_stage_of_site_c)}
						{site?.contract_phone && (
							<Link to={'tel:+91' + site?.contract_phone} aria-label="call user" className="call">
								<span className="icon-call"></span>
							</Link>
						)}
					</div>
				</div>

				<div className="summary-details-bottom">
					<div className="left-content">
						<span className="contractor-name h5">
							Contractor Name: <span className="name">{site?.contract_name}</span>
						</span>
						<span className="contractor-name h5">
							Membership No.: <span className="name">{site?.membership_no}</span>
						</span>
						{site?.createddate && (
							<span className="visit-date h5">
								Last Visit: <span className="date">{convertISODateTODDMMYY(site.lastactivitydate)}</span>
							</span>
						)}
					</div>

					<div className="right-content">
						<NavLink
							to={''}
							aria-label="close site"
							className="close-site"
							id="openDialog"
							onClick={handleSiteStatusChange}
						>
							<span
								title={!site?.site_lead_status ? 'Site Not Converted' : `Site Converted to ${site?.site_lead_status}`}
								className="h5"
							>
								{getSiteConversionStatus(site?.site_lead_status)}
							</span>
						</NavLink>
					</div>
				</div>
			</div>
		</li>
	);
};
