import { AddressFields, Checkbox, SubmitButton, TextInput } from '@components/common';
import { townDropdownData } from '@config/constant';
import { useFormik } from 'formik';
import { ReactElement, useCallback, useEffect } from 'react';
import { PrimaryDetailList } from './primaryDetailList';
import {
	defaultAccordianDetails,
	defaultCompetitionDetails,
	defaultMoreDetailFormInitialValues,
	defaultPrimaryDetails,
	toNumberOrNull,
} from '.';
import { CompetitionDetailList } from './competitionDetails';
import { AccordianDetailList } from './accordianDetailList';
import { useMoreDetailsMutation } from '@services/hooks/enduser-details/useMoreDetailsMutation';
import { useNavigate } from 'react-router-dom';
import { useValidation } from '@hooks/validations';
import {
	compareFactoryAddresses,
	compareWorkShopAddresses,
	getArchitectDetails,
	getCompetitionArray,
	getDealerDetails,
	keyDownEvent,
} from '@helper/utils';
import { useMoreDetailsQuery } from '@services/hooks/enduser-details/useMoreDetailQuery';
import { useSuccessTosterStore } from '@store/successToster';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useEndUserStore } from '@store/enduser';
import { useLeadUserQuery } from '@services/hooks/endusers/leadUserQuery';
import { useAllEndUserQuery } from '@services/hooks/endusers/allEndUserQuery';
import { ToastType, useTosterStore } from '@store/toster';

export const MoreDetailsForm = (): ReactElement => {
	const nav = useNavigate();
	const { isEditing, setIsEditing, isFormEdit, formId } = useEndUserStore();
	const { data: getMoreDetailFormData, isFetching: isMoreDetailFetching } = useMoreDetailsQuery(formId, isFormEdit);
	const {
		mutate,
		isPending: isMoreDetailFormPending,
		error: isMoreDetailFormError,
	} = useMoreDetailsMutation(formId, isFormEdit);
	const { MoreDetailsValidationSchema } = useValidation();
	const { setSuccessModalOpen } = useSuccessTosterStore();
	const { refetch: leadUserRefetch } = useLeadUserQuery();
	const { refetch: allEndUserRefetch } = useAllEndUserQuery();
	const { setToaster } = useTosterStore();

	const formik = useFormik({
		initialValues: defaultMoreDetailFormInitialValues,
		onSubmit: () => {
			handleSubmitForm();
		},
		validationSchema: MoreDetailsValidationSchema,
	});

	useEffect(() => {
		if (!formId && getMoreDetailFormData?.status !== 'Kyc Details') {
			nav('/add-member-details/basic-details');
		}
	}, [formId, nav, getMoreDetailFormData]);

	const handleSubmitForm = useCallback(() => {
		const payload = {
			primarydealercode: formik.values.primary_details[0]?.dealer_code?.split('-')[0]?.trim() ?? null,
			primarydealername: formik.values.primary_details[0]?.dealer_name ?? null,
			alternate1dealername_c: formik.values.primary_details[1]?.dealer_name ?? null,
			alternate1dealercode_c: formik.values.primary_details[1]?.dealer_code?.split('-')[0]?.trim() ?? null,
			alternate2dealername_c: formik.values.primary_details[2]?.dealer_name ?? null,
			alternate2dealercode_c: formik.values.primary_details[2]?.dealer_code?.split('-')[0]?.trim() ?? null,
			monthlyconsumption_c: formik.values.average_glue_potential,
			workshoppincode_c: formik.values.workshop_checkbox ? formik.values.workshop_zipcode : null,
			workshopstate_c: formik.values.workshop_checkbox ? formik.values.workshop_state : null,
			workshop_c: formik.values.workshop_checkbox,
			workshopcity_c: formik.values.workshop_checkbox ? formik.values.workshop_city : null,
			workshopaddress1_c: formik.values.workshop_checkbox ? formik.values.workshop_address : null,
			workshopaddress2_c: formik.values.workshop_checkbox ? formik.values.workshop_street : null,
			status: 'More Details',
			convert_lead_c: 'True',
			competition_brand_1_consumption_c: toNumberOrNull(formik.values.competition_details[0]?.competition_volume),
			competition_brand_1_used_c: formik.values.competition_details[0]?.competition_brands ?? null,
			competition_brand_2_consumption_c: toNumberOrNull(formik.values.competition_details[1]?.competition_volume),
			competition_brand_2_used_c: formik.values.competition_details[1]?.competition_brands ?? null,
			competition_brand_3_consumption_c: toNumberOrNull(formik.values.competition_details[2]?.competition_volume),
			competition_brand_3_used_c: formik.values.competition_details[2]?.competition_brands ?? null,
			furniture_address_c: formik.values.factory_checkbox ? formik.values.factory_address : null,
			furniture_city_c: formik.values.factory_checkbox ? formik.values.factory_city : null,
			furniture_pincode_c: formik.values.factory_checkbox ? formik.values.factory_zipcode : null,
			furniture_state_c: formik.values.factory_checkbox ? formik.values.factory_state : null,
			additionenduser: {
				architect_name1_c: formik.values.architect_details[0]?.name ?? null,
				architect_phone1_c: toNumberOrNull(formik.values.architect_details[0]?.number),
				architect_name2_c: formik.values.architect_details[1]?.name ?? null,
				architect_phone2_c: toNumberOrNull(formik.values.architect_details[1]?.number),
				architect_name3_c: formik.values.architect_details[2]?.name ?? null,
				architect_phone3_c: toNumberOrNull(formik.values.architect_details[2]?.number),
			},
		};
		if (formId) {
			mutate(payload, {
				onSuccess: () => {
					leadUserRefetch?.();
					allEndUserRefetch?.();
					setSuccessModalOpen('/end-users/all', 'Your member details have been saved successfully!');
				},
				onError: (error) => {
					setToaster(true, ToastType.error, error?.message);
				},
			});
		}
	}, [formik.values, formId, mutate, leadUserRefetch, allEndUserRefetch, setSuccessModalOpen]);

	const handleSubmitClick = (): void => {
		formik.handleSubmit();
	};

	useEffect(() => {
		if (formId && getMoreDetailFormData && Object.keys(getMoreDetailFormData).length > 0) {
			const primaryDetailsArr = getDealerDetails(getMoreDetailFormData);
			const architectDetailsArr = getArchitectDetails(getMoreDetailFormData);
			const competitionDetailsArr = getCompetitionArray(getMoreDetailFormData);

			formik.setValues({
				...formik.values,
				primary_details: primaryDetailsArr.length > 0 ? primaryDetailsArr : defaultPrimaryDetails,
				average_glue_potential: getMoreDetailFormData.monthlyconsumption_c,
				workshop_zipcode: getMoreDetailFormData.workshoppincode_c,
				workshop_state: getMoreDetailFormData.workshopstate_c,
				workshop_city: getMoreDetailFormData.workshopcity_c,
				workshop_address: getMoreDetailFormData.workshopaddress1_c,
				workshop_street: getMoreDetailFormData.workshopaddress2_c,
				workshop_checkbox: !compareWorkShopAddresses(getMoreDetailFormData),
				competition_details: competitionDetailsArr.length > 0 ? competitionDetailsArr : defaultCompetitionDetails,
				architect_details: architectDetailsArr.length > 0 ? architectDetailsArr : defaultAccordianDetails,
				factory_address: getMoreDetailFormData?.furniture_address_c,
				factory_city: getMoreDetailFormData?.furniture_city_c,
				factory_state: getMoreDetailFormData?.furniture_state_c,
				factory_zipcode: getMoreDetailFormData?.furniture_pincode_c,
				factory_checkbox: !compareFactoryAddresses(getMoreDetailFormData),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getMoreDetailFormData, formId]);

	return (
		<div>
			<div className="all-user-details-form">
				<Wrapper isLoaderVisible={isMoreDetailFetching} isError={isMoreDetailFormError}>
					<form onSubmit={formik.handleSubmit}>
						<ul className="user-details-wrapper row">
							{formik.values.primary_details.map((dealer, index) => (
								<PrimaryDetailList
									key={dealer.id}
									formik={formik}
									primaryListIndex={index}
									dealer={dealer}
									fieldName="primary_details"
									isEditing={isEditing}
								/>
							))}

							<li className="form-control d-col d-col-2">
								<TextInput
									id="average_glue_potential"
									name="average_glue_potential"
									type="text"
									onBlur={formik.handleBlur}
									value={formik.values.average_glue_potential ?? ''}
									setFieldValue={formik.setFieldValue}
									label="Avg monthly Glue Potential (kgs)"
									error={
										formik.touched.average_glue_potential && formik.errors.average_glue_potential
											? formik.errors.average_glue_potential
											: null
									}
									onKeyDown={keyDownEvent}
									disabled
									required
								/>
							</li>

							{formik.values.competition_details.map((competition, index) => (
								<CompetitionDetailList
									key={competition.id}
									formik={formik}
									competitionListIndex={index}
									competition={competition}
									fieldName="competition_details"
									isEditing={isEditing}
								/>
							))}

							<li className="d-col">
								<Checkbox
									id="workshop_checkbox"
									name="workshop_checkbox"
									type="checkbox"
									checked={formik.values.workshop_checkbox}
									onChange={formik.handleChange}
									label="Do you have a workshop?"
									disabled={isEditing}
								/>
							</li>
							{formik.values.workshop_checkbox && (
								<AddressFields
									mainFieldName="workshop"
									formik={formik}
									townName="workshop_town"
									addressName="workshop_address"
									zipcodeName="workshop_zipcode"
									cityName="workshop_city"
									streetName="workshop_street"
									stateName="workshop_state"
									addressLabel="Workshop Address"
									townsList={townDropdownData}
									townLabel="Type of Workshop"
									isEditing={isEditing}
								/>
							)}

							<li className="d-col">
								<Checkbox
									id="factory_checkbox"
									name="factory_checkbox"
									type="checkbox"
									checked={formik.values.factory_checkbox}
									onChange={formik.handleChange}
									label="Do you have a factory/mechanized unit?"
									disabled={isEditing}
								/>
							</li>

							{formik.values.factory_checkbox && (
								<AddressFields
									mainFieldName="factory"
									formik={formik}
									addressName="factory_address"
									zipcodeName="factory_zipcode"
									cityName="factory_city"
									stateName="factory_state"
									addressLabel="Factory Address"
									isEditing={isEditing}
								/>
							)}

							{formik.values.architect_details.map((architect, index) => (
								<AccordianDetailList
									key={architect.id}
									title="Architect and Interior Designer Details"
									accordianName="architect_details"
									accordianListIndex={index}
									formik={formik}
									data={architect}
									isEditing={isEditing}
									isAddition
								/>
							))}
						</ul>

						<SubmitButton
							label="Submit"
							isEditing={isEditing}
							isPending={isMoreDetailFormPending}
							isDisabled={!formik.isValid || !formik.dirty || isMoreDetailFormPending}
							setIsEditing={setIsEditing}
							handleSubmitClick={handleSubmitClick}
						/>
					</form>
				</Wrapper>
			</div>
		</div>
	);
};
