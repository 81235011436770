import Cookies from 'universal-cookie';

export const getTopLevelDomain = (): string => {
	const domainParts = window.location.hostname.split('.');
	return domainParts.slice(-2).join('.');
};
export const clearAllCookies = (): void => {
	const topLevelDomain = getTopLevelDomain();

	const cookies = new Cookies();
	const allCookies = cookies.getAll();
	Object.keys(allCookies).forEach((cookieName) => {
		cookies.remove(cookieName, { path: '/' });
		cookies.remove(cookieName, { path: '/', domain: `.${topLevelDomain}` });
		cookies.remove(cookieName, { path: '/', domain: topLevelDomain });
	});
};
