import { memo, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { DropdownOptions, IDropDown } from '.';
import { DropdownList } from './dropdownlist';
import { useOutsideClick } from '@hooks/useOutsideClick';
import { noDataDropdownOptions } from '@config/constant';
import { useScrollIntoView } from '@hooks/usescrollIntoView';
import { useOutsideDialogClose } from '@hooks/useOutsideDialogClose';
import { useInfiniteScroll } from '@components/common';

const Dropdown = (props: IDropDown): ReactElement => {
	const {
		title,
		label,
		name,
		error,
		options,
		required,
		setFieldValue,
		onBlur,
		disabled,
		additionalLabel,
		allOptions,
		placeholder,
		icon,
		className = '',
		onChange,
		isAutoFocus = false,
		onInputChange,
		blurValidate = true,
		listFetchMore,
		handelKeyDownProp,
		listFetching,
	} = props;
	const inputRef = useRef<HTMLDivElement | null>(null);
	const ulList = useRef<HTMLUListElement | null>(null);
	useOutsideDialogClose({
		dialogRef: inputRef,
		callBackFunction: () => setIsOpen(false),
	});
	const dropdownRef = useRef<HTMLInputElement>(null);
	const { handleFocus } = useScrollIntoView(dropdownRef);
	const { isOpen, setIsOpen } = useOutsideClick({ dropdownRef });
	const [dropdownTitle, setDropdownTitle] = useState<string | undefined>(title);
	const [filteredOptions, setFilteredOptions] = useState<DropdownOptions[]>(options);
	const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1);

	useEffect(() => {
		setDropdownTitle(title);
	}, [title]);

	useInfiniteScroll(() => {
		listFetchMore?.();
	}, ulList);

	const handleDropdown = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>): void => {
			const inputValue = e.target.value;
			onInputChange?.(inputValue);
			setDropdownTitle(inputValue);
			setFilteredOptions(options.filter((option) => option.value?.toLowerCase().includes(inputValue.toLowerCase())));
			name && setFieldValue && setFieldValue(name, inputValue);
		},
		[name, onInputChange, options, setFieldValue]
	);

	const filterRest = (): void => {
		if (filteredOptions[focusedOptionIndex] && setFieldValue && name) {
			const selectedOption = filteredOptions[focusedOptionIndex];
			setDropdownTitle(selectedOption.value);
			setFieldValue(name, selectedOption.value);
			onChange && onChange({ id: selectedOption.id, name: name, value: selectedOption.value });
		}
		setIsOpen(false);
	};

	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>): void => {
			handelKeyDownProp?.(e);
			switch (e.key) {
				case 'ArrowDown':
					e.preventDefault();
					setIsOpen(true);
					setFocusedOptionIndex((prevIndex) => (prevIndex + 1) % filteredOptions.length);
					break;
				case 'ArrowUp':
					e.preventDefault();
					setFocusedOptionIndex((prevIndex) => (prevIndex === 0 ? filteredOptions.length - 1 : prevIndex - 1));
					break;
				case 'Enter':
					e.preventDefault();
					filterRest();
					break;
				case 'Tab':
					filterRest();
					break;
				case 'Escape':
					e.preventDefault();
					setIsOpen(false);
					break;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filteredOptions, focusedOptionIndex, name, setFieldValue, setIsOpen]
	);

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
		onBlur && onBlur(e);
		const updatedOptions = allOptions || filteredOptions;
		let inputValueExists: boolean;
		if (blurValidate) {
			const inputValue = dropdownTitle ?? title;
			inputValueExists = updatedOptions?.some((option) => option.value === inputValue);
		} else {
			inputValueExists = updatedOptions?.some((option) => (option.value === dropdownTitle ? dropdownTitle : title));
		}
		if (!inputValueExists && name && setFieldValue) {
			setDropdownTitle('');
			setFieldValue(name, '');
		}
	};

	useEffect(() => {
		if (isOpen) return;
		if (!listFetching) {
			setFilteredOptions(options);
		}
		setFocusedOptionIndex(-1);
	}, [isOpen, listFetching, options]);

	useEffect(() => {
		if (isAutoFocus && dropdownRef.current) {
			dropdownRef.current.focus();
			setIsOpen(true);
		}
	}, [isAutoFocus, setIsOpen]);

	useEffect(() => {
		if (listFetching && options) {
			setFilteredOptions(options);
		}
	}, [listFetching, options]);

	return (
		<>
			<div ref={inputRef} className="select-category-box">
				<input
					ref={dropdownRef}
					type="text"
					placeholder={placeholder}
					value={title ?? dropdownTitle}
					onChange={handleDropdown}
					onBlur={handleBlur}
					onFocus={handleFocus}
					onKeyDown={handleKeyDown}
					name={name}
					id={name}
					className={`select-input ${className}`}
					autoComplete="off"
					disabled={disabled}
				/>
				<span className={`icon-bottom-custom ${icon ?? 'icon-bottom-arrow'}`}></span>
				<span className={`custom-label ${required ? 'required' : ''}`}>{label}</span>
				{additionalLabel && <span className="currency-name">{additionalLabel}</span>}
				<div className={`common-dropdown-wrapper ${isOpen && !disabled ? 'show' : ''}`}>
					<ul className="dropdown-list" ref={ulList}>
						{filteredOptions?.length > 0 ? (
							filteredOptions?.map((data: DropdownOptions, index: number) => (
								<DropdownList
									key={`${data.id}_${data.value}`}
									id={data.id}
									name={name}
									value={data.value}
									setFieldValue={setFieldValue}
									title={title}
									options={filteredOptions}
									setDropdownTitle={setDropdownTitle}
									isFocused={index === focusedOptionIndex}
									onChange={(e) => {
										onChange && onChange(e);
										setIsOpen(false);
									}}
								/>
							))
						) : (
							<DropdownList
								id="no-data"
								name="no-data"
								value="No data"
								title="No data"
								options={noDataDropdownOptions}
							/>
						)}
					</ul>
				</div>
			</div>
			{error && <p className={`error-message ${error && 'show'}`}>{error}</p>}
		</>
	);
};

export default memo(Dropdown);
