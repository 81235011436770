import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { TSIFormProps } from '.';
import { Dropdown, TextInput } from '@components/common';
import { DropdownOptions } from '@components/common/formControl/dropdown';
import { IEndUserCategoryList, IPrimaryDealerList } from '@components/onetoonemeetings';
import { useGetRxDb } from '@hooks/getRxdbData';
import usePincodeQuery from '@services/hooks/enduser-details/usePincodeQuery';
import { InitialQuickUser } from '../addQuickUser';
import { useFormError } from '@hooks/useFormError';
import { keyDownEvent } from '@helper/utils';

export const TSIMeetingFormFields = (props: TSIFormProps): ReactElement => {
	const { formik } = props;
	const { result: endUserCategoryList } = useGetRxDb<IEndUserCategoryList>('enduser_category_list');
	const { result: primaryDealersList } = useGetRxDb<IPrimaryDealerList>('dealers_list');
	const { state, city, setPincode } = usePincodeQuery();
	const getErrorMessage = useFormError<InitialQuickUser>(formik);

	useEffect(() => {
		if (state && city) {
			formik.setFieldValue('state', state);
			formik.setFieldValue('city', city);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state, city]);

	const enduserCategories = useMemo(() => {
		if (endUserCategoryList?.length) {
			return endUserCategoryList.map((d, index) => ({
				id: index,
				value: d?.name,
			}));
		}
		return [];
	}, [endUserCategoryList]);

	const primaryDealers = useMemo(() => {
		if (primaryDealersList?.length) {
			return [...primaryDealersList]
				.sort((a, b) => a.dealername_c.localeCompare(b.dealername_c))
				.map((d) => ({
					id: d?.dealercode_c,
					value: `${d?.dealername_c} - ${d?.dealercode_c}`,
				}));
		}
		return [];
	}, [primaryDealersList]);

	const handleDealerChange = useCallback(
		(e: DropdownOptions): void => {
			const dealer = primaryDealersList?.find((d) => d.dealercode_c === e?.id);
			if (dealer) {
				formik.setFieldValue(
					'wssTerritory',
					`${dealer?.hierarchylevel1wssterritory_c} - ${dealer?.descriptionofhierarchylevel1_c}`
				);
				formik.setFieldValue('tsiTerritory', `${dealer?.tsicode_c} - ${dealer?.tsiname_c}`);
				formik.setFieldValue('town', `${dealer?.citycode_c} - ${dealer?.towndescription_c}`);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[primaryDealersList]
	);

	const handleZipcodeChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>, field?: string): void => {
			setPincode(event.target.value);
			if (event.target.value.length < 6) {
				formik.setFieldValue('state', '');
				formik.setFieldValue('city', '');
				return;
			}
			field && formik.setFieldValue(field, event.target.value);
		},
		[formik, setPincode]
	);
	return (
		<>
			<li className="form-control d-col ">
				<Dropdown
					id="endUserCategory"
					name="endUserCategory"
					label="End User Category"
					onBlur={formik.handleBlur}
					title={formik.values.endUserCategory ?? ''}
					options={enduserCategories}
					setFieldValue={formik.setFieldValue}
					error={getErrorMessage('endUserCategory')}
					blurValidate
					required
				/>
			</li>

			<li className="form-control d-col ">
				<Dropdown
					id="primaryDealer"
					name="primaryDealer"
					label="Primary Dealer"
					onBlur={formik.handleBlur}
					title={formik.values.primaryDealer ?? ''}
					options={primaryDealers}
					setFieldValue={formik.setFieldValue}
					error={getErrorMessage('primaryDealer')}
					onChange={handleDealerChange}
					blurValidate
					required
				/>
			</li>

			<li className="form-control d-col ">
				<TextInput
					id="wssTerritory"
					name="wssTerritory"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.wssTerritory}
					label="WSS Territory"
					setFieldValue={formik.setFieldValue}
					error={getErrorMessage('wssTerritory')}
					disabled
					required
				/>
			</li>
			<li className="form-control d-col ">
				<TextInput
					id="tsiTerritory"
					name="tsiTerritory"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.tsiTerritory}
					label="TSI Territory"
					setFieldValue={formik.setFieldValue}
					error={getErrorMessage('tsiTerritory')}
					disabled
				/>
			</li>
			<li className="form-control d-col ">
				<TextInput
					id="town"
					name="town"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.town}
					label="Town"
					setFieldValue={formik.setFieldValue}
					error={getErrorMessage('town')}
					disabled
					required
				/>
			</li>
			<li className="form-control d-col ">
				<TextInput
					id="zipcode"
					name="zipcode"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.zipcode}
					label="Zipcode"
					setFieldValue={formik.setFieldValue}
					onChange={handleZipcodeChange}
					error={getErrorMessage('zipcode')}
					onKeyDown={keyDownEvent}
					maxLength={6}
					required
				/>
			</li>
			<li className="form-control d-col ">
				<TextInput
					id="city"
					name="city"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.city}
					label="City"
					setFieldValue={formik.setFieldValue}
					error={getErrorMessage('city')}
					disabled
				/>
			</li>
			<li className="form-control d-col ">
				<TextInput
					id="state"
					name="state"
					type="text"
					onBlur={formik.handleBlur}
					value={formik.values.state}
					label="State"
					setFieldValue={formik.setFieldValue}
					error={getErrorMessage('state')}
					disabled
				/>
			</li>
		</>
	);
};
