import * as Yup from 'yup';

export interface ITSIContractorDetailFormFields {
	firstName: Yup.StringSchema;
	lastName: Yup.StringSchema;
	mobileNumber: Yup.StringSchema;
	primaryDealer: Yup.StringSchema;
	wssTerritory: Yup.StringSchema;
	tsiTerritory: Yup.StringSchema;
	town: Yup.StringSchema;
	productImages: Yup.ArraySchema<File[] | undefined, Yup.AnyObject, undefined, ''>;
}

export const useTSIContractorDetailFormFields = (): ITSIContractorDetailFormFields => {
	const firstName = Yup.string().when('action', {
		is: 'Manually',
		then: (schema) =>
			schema
				.required('First Name is required')
				.matches(/^(?!\s)(?=.*[a-zA-Z])[a-zA-Z\s.]+$/, 'Only English characters are allowed in the first name'),
		otherwise: (schema) => schema.notRequired(),
	});

	const lastName = Yup.string().when('action', {
		is: 'Manually',
		then: (schema) =>
			schema
				.required('Last Name is required')
				.matches(/^(?!\s)(?=.*[a-zA-Z])[a-zA-Z\s.]+$/, 'Only English characters are allowed in the last name'),
		otherwise: (schema) => schema.notRequired(),
	});

	const mobileNumber = Yup.string().when('action', {
		is: 'Manually',
		then: (schema) =>
			schema
				.required('Mobile Number is required')
				.matches(/^[1-9]\d{9}$/, 'Mobile Number must be 10 digits long and not start with 0'),
		otherwise: (schema) => schema.notRequired(),
	});

	const primaryDealer = Yup.string().when('action', {
		is: 'Manually',
		then: (schema) => schema.required('Primary Dealer is required'),
		otherwise: (schema) => schema.notRequired(),
	});

	const wssTerritory = Yup.string().when('action', {
		is: 'Manually',
		then: (schema) => schema.required('WSS Territory is required'),
		otherwise: (schema) => schema.notRequired(),
	});

	const tsiTerritory = Yup.string().when('action', {
		is: 'Manually',
		then: (schema) => schema.required('TSI Territory is required'),
		otherwise: (schema) => schema.notRequired(),
	});
	const town = Yup.string().when('action', {
		is: 'Manually',
		then: (schema) => schema.required('Town is required'),
		otherwise: (schema) => schema.notRequired(),
	});
	const productImages = Yup.array().when('action', {
		is: 'Image Upload',
		then: (schema) => schema.min(1, 'At least one image is required').required('Product Images are required'),
		otherwise: (schema) => schema.notRequired(),
	});

	return { firstName, lastName, mobileNumber, primaryDealer, wssTerritory, tsiTerritory, town, productImages };
};
