import { siteStatusList } from '@components/siteSummary/components';

export interface ITypeOfContractor {
	id: number;
	value: string;
	name: string;
}

export const townDropdownData = [
	{ id: 0, value: 'Announcement' },
	{ id: 1, value: 'Corporate' },
	{ id: 2, value: 'Engagement' },
	{ id: 3, value: 'Event' },
	{ id: 4, value: 'Insights' },
	{ id: 5, value: 'People' },
	{ id: 6, value: 'Product launch' },
];

export const statesDropdownData = [
	{ id: 0, value: 'Andhra Pradesh' },
	{ id: 1, value: 'Arunachal Pradesh' },
	{ id: 2, value: 'Assam' },
	{ id: 3, value: 'Bihar' },
	{ id: 4, value: 'Chhattisgarh' },
	{ id: 5, value: 'Goa' },
	{ id: 6, value: 'Gujarat' },
	{ id: 7, value: 'Haryana' },
	{ id: 8, value: 'Himachal Pradesh' },
	{ id: 9, value: 'Jammu and Kashmir' },
	{ id: 10, value: 'Jharkhand' },
	{ id: 11, value: 'Karnataka' },
	{ id: 12, value: 'Kerala' },
	{ id: 13, value: 'Madhya Pradesh' },
	{ id: 14, value: 'Maharashtra' },
	{ id: 15, value: 'Manipur' },
	{ id: 16, value: 'Meghalaya' },
	{ id: 17, value: 'Mizoram' },
	{ id: 18, value: 'Nagaland' },
	{ id: 19, value: 'Odisha' },
	{ id: 20, value: 'Punjab' },
	{ id: 21, value: 'Rajasthan' },
	{ id: 22, value: 'Sikkim' },
	{ id: 23, value: 'Tamil Nadu' },
	{ id: 24, value: 'Telangana' },
	{ id: 25, value: 'Tripura' },
	{ id: 26, value: 'Uttarakhand' },
	{ id: 27, value: 'Uttar Pradesh' },
	{ id: 28, value: 'West Bengal' },
	{ id: 29, value: 'Andaman and Nicobar Islands' },
	{ id: 30, value: 'Chandigarh' },
	{ id: 31, value: 'Daman and Diu' },
	{ id: 32, value: 'Delhi' },
	{ id: 33, value: 'Lakshadweep' },
	{ id: 34, value: 'Puducherry' },
];

export const religions = [
	{ id: 1, value: 'Hinduism' },
	{ id: 2, value: 'Sikhism' },
	{ id: 3, value: 'Buddism' },
	{ id: 4, value: 'Christianity' },
	{ id: 5, value: 'Islam' },
];

export const genders = [
	{ id: 1, value: 'Female' },
	{ id: 2, value: 'Male' },
	{ id: 3, value: 'Other' },
];

export const languages = [
	{ id: 0, language: 'uz', value: 'Assamese' },
	{ id: 1, language: 'bn', value: 'Bengali' },
	{ id: 2, language: 'gu', value: 'Gujarati' },
	{ id: 3, language: 'hi', value: 'Hindi' },
	{ id: 4, language: 'kn', value: 'Kannada' },
	{ id: 5, language: 'ml', value: 'Malayalam' },
	{ id: 6, language: 'mr', value: 'Marathi' },
	{ id: 7, language: 'mn', value: 'Manipuri' },
	{ id: 8, language: 'mi', value: 'Mizoram' },
	{ id: 9, language: 'me', value: 'Meghalaya' },
	{ id: 1, language: 'ne', value: 'Nepali' },
	{ id: 10, language: 'ne', value: 'Ladakhi' },
	{ id: 11, language: 'or', value: 'Oriya' },
	{ id: 12, language: 'pa', value: 'Punjabi' },
	{ id: 13, language: 'ks', value: 'Kashmiri' },
	{ id: 14, language: 'ta', value: 'Tamil' },
	{ id: 15, language: 'te', value: 'Telugu' },
	{ id: 16, language: 'ur', value: 'Urdu' },
	{ id: 17, language: 'en', value: 'English' },
	{ id: 18, language: 'ot', value: 'Other' },
];

export const hobbies = [
	{ id: 0, value: 'Dancing' },
	{ id: 1, value: 'Singing' },
	{ id: 2, value: 'Reading' },
	{ id: 3, value: 'Other' },
];

export const documents = [
	{ id: 0, value: 'Voter Card' },
	{ id: 1, value: 'Driving License' },
	{ id: 2, value: 'Pan Card' },
	{ id: 3, value: 'Aadhaar Card' },
];

export const noDataDropdownOptions = [{ id: 0, value: 'No data' }];

export const ensUserNavData = [
	{
		id: 0,
		title: 'Basic Details',
		childRoute: '/basic-details',
		mainRoutes: '/add-member-details',
		editMainRoutes: '/edit-member-details',
	},
	{
		id: 1,
		title: 'Kyc Details',
		childRoute: '/kyc-details',
		mainRoutes: '/add-member-details',
		editMainRoutes: '/edit-member-details',
	},
	{
		id: 2,
		title: 'More Details',
		childRoute: '/more-details',
		mainRoutes: '/add-member-details',
		editMainRoutes: '/edit-member-details',
	},
];

export const phoneRegExp = /^0[1-9]\d{9}$|^[1-9]\d{9}$/;

export const closeLeadsDropDown = [
	{ id: 1, value: 'Lead Transfer', name: 'lead_transfer' },
	{ id: 2, value: 'Existing Contractor', name: 'existing_contractor' },
	{ id: 3, value: 'Carpenter', name: 'carpenter' },
	{ id: 4, value: 'Dealer/WSS/Shopboy', name: 'dealer_wss_shopboy' },
	{ id: 5, value: 'Company Employee', name: 'company_employee' },
	{ id: 6, value: 'Is a Painter', name: 'painter' },
	{ id: 7, value: 'Is a  Plumber', name: 'plumber' },
	{ id: 8, value: 'Noe of the above but not a contractor', name: 'none_of_the_above' },
];

export const typeOfContractor: ITypeOfContractor[] = [
	{
		id: 0,
		value: 'Labor',
		name: 'labour',
	},
	{
		id: 1,
		value: 'Labor + Material',
		name: 'material_labour',
	},
	{
		id: 2,
		value: 'Percentage',
		name: 'percentage',
	},
];

export const gradeAndBrandValues = [
	{
		id: 0,
		value: 'Fevicol SH',
	},
	{
		id: 1,
		value: 'Fevicol Marine',
	},
	{
		id: 2,
		value: 'Fevicol Hi Per',
	},
	{
		id: 3,
		value: 'Fevicol Hi Per Star',
	},
];

export const glueUsedRadioTypeList = [
	{
		id: 0,
		value: 'FV',
	},
	{
		id: 1,
		value: 'F&B',
	},
	{
		id: 2,
		value: 'Competition',
	},
];

export const siteRadioTypeList = [
	{
		id: 0,
		value: 'Residential',
	},
	{
		id: 1,
		value: 'Commercial',
	},
];

export const calendarHeader = [
	{ id: 0, name: 'Day', value: 'day' },
	{ id: 1, name: 'Week', value: 'week' },
	{ id: 2, name: 'Month', value: 'month' },
];

export const gluePotentialofSiteList = [
	{ id: 0, value: '< 100' },
	{ id: 1, value: '100 - 250' },
	{ id: 2, value: '251 - 500' },
	{ id: 3, value: '501 - 1000' },
	{ id: 4, value: '> 1000' },
];

export const galleryHeader = [
	{ id: 0, name: 'Images', value: 'images' },
	{ id: 2, name: 'Documents', value: 'documents' },
];

export const brandUsedList = [
	{ id: 0, value: 'Euro' },
	{ id: 1, value: 'Astral' },
	{ id: 2, value: 'Asian Paints' },
	{ id: 3, value: 'Jivanjor' },
	{ id: 4, value: 'American Bond' },
	{ id: 5, value: 'Mahacol' },
	{ id: 6, value: 'Others' },
	{ id: 7, value: 'NA' },
];

export const maxFileSize = 5 * 1024 * 1024;

export const meetingTheme = [
	{ id: 0, value: 'Govt Scheme Awareness' },
	{ id: 1, value: 'Musical' },
	{ id: 2, value: 'Health Awareness' },
];

export const meetFormate = [
	{ id: 0, value: 'Physical' },
	{ id: 1, value: 'Virtual' },
];

export const quarterMonths: string[] = ['Jan-Mar', 'Apr-Jun', 'Jul-Sep', 'Oct-Dec'];

export const siteStatusLists: siteStatusList[] = [
	{
		id: '1',
		name: 'current_stage_of_site_c',
		value: 'Site Visited',
		label: 'Visited',
	},
	{
		id: '2',
		name: 'current_stage_of_site_c',
		value: 'New Lead',
		label: 'New Lead',
	},
];

export const Categories = [
	{ id: '1', value: 'Pidilite Employee' },
	{ id: '2', value: 'WSS' },
	{ id: '3', value: 'Dealer' },
	{ id: '4', value: 'Helper/Junior Staff' },
	{ id: '5', value: 'RCI' },
];
export const DesignationList = [
	{ id: '1', value: 'RSM/ZSM' },
	{ id: '2', value: 'BM/ASM' },
	{ id: '3', value: 'RBDM/RMDM/AMMD/CSFM' },
	{ id: '4', value: 'TSI' },
	{ id: '5', value: 'HO Manager' },
	{ id: '6', value: 'Promoter' },
	{ id: '7', value: 'CMDI' },
];

export const YesNo = [
	{ id: '1', value: 'Yes' },
	{ id: '2', value: 'No' },
];

export const NatureOfComplaints = [
	{ id: '1', value: 'Product Quality Complaint' },
	{ id: '2', value: 'Packaging Complaint' },
	{ id: '3', value: 'Expired Product' },
	{ id: '4', value: 'Product Application Complaint' },
	{ id: '5', value: 'Shade Variation Complaint' },
];

export const UOM = [
	{ id: '1', value: 'EA (Pieces)' },
	{ id: '2', value: 'KG - Kilograms' },
	{ id: '3', value: 'L - Liters' },
	{ id: '4', value: 'SET' },
];

export const TypesOfComplaints = [
	{ id: '1', value: 'User' },
	{ id: '2', value: 'Dealer' },
	{ id: '3', value: 'Customer/Homeowner' },
];

export const SampleNonAvailabilityReason = [
	{ id: '1', value: 'Product used on the site' },
	{ id: '2', value: 'Product pack no longer available with user' },
	{ id: '3', value: 'Post application complaint' },
	{ id: '4', value: 'Others' },
];
