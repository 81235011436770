import { useRxData } from 'rxdb-hooks';
import { escapeRegExp } from '@helper/utils';
import { MangoQuerySelector, RxCollection } from 'rxdb';
import { useCallback } from 'react';
import { useDebounceSearch } from '@hooks/useDebounceSearch';
import { getFilter } from '@hooks/useFilterData';
import { useFilterStore } from '@store/filter';
import { SelectorFilter } from '@store/memberList';
import { MemberObject, MemberObjectResultType, MemberSearchFilter } from '.';
import { useRxCountQuery } from '@hooks/useCount';

export const GetMemberListData = (name: string): MemberObjectResultType => {
	const { sortValue, filterObject, search } = useFilterStore((state) => state);
	const debouncedSearch = useDebounceSearch(search);
	const queryConstructor = useCallback(
		(collection: RxCollection<MemberObject>) => {
			const { selectorData } = getFilter(filterObject);
			type CombinedSelectorType = SelectorFilter & MemberSearchFilter & MangoQuerySelector<MemberObject>;

			const selector: CombinedSelectorType = selectorData as CombinedSelectorType;

			if (debouncedSearch && debouncedSearch.trim() !== '') {
				const searchData = escapeRegExp(debouncedSearch);
				selector.$or = [
					{ name: { $regex: `.*${searchData}.*`, $options: 'i' } },
					{ phone: { $regex: `.*${searchData}.*`, $options: 'i' } },
				];

				if (name !== 'new_leads') {
					selector.$or.push({ membershipno: { $regex: `.*${searchData}.*`, $options: 'i' } });
				}
			}

			(Object.keys(selector) as Array<keyof (SelectorFilter & MemberSearchFilter)>).forEach((key) => {
				if (selector[key] === undefined || selector[key] === null) {
					delete selector[key];
				}
			});

			const defaultSelector = Object.keys(selector).length > 0 ? selector : {};
			let query = collection.find({ selector: defaultSelector });

			if (sortValue) {
				const isSpecialSort = ['pwg_banking_qtd', 'glue_consumption_per_month'].includes(sortValue);
				const sortDirection = isSpecialSort || (name === 'new_leads' && sortValue === 'createddate') ? 'desc' : 'asc';

				query = query.sort({ [sortValue]: sortDirection });
			}
			query = query.sort({ createddate: 'desc' });

			return query;
		},
		[filterObject, debouncedSearch, sortValue, name]
	);
	const totalCount = useRxCountQuery<MemberObject>(name, queryConstructor);

	const { result, isFetching, fetchMore } = useRxData<MemberObject>(name, queryConstructor, {
		pageSize: 20,
		pagination: 'Infinite',
	});

	return { result, fetchMore, isFetching, totalCount };
};
