import { RoleType } from '@helper/utils';
import * as Yup from 'yup';

export interface ITSIQuickEndMeetingFormFields {
	primaryDealer: Yup.StringSchema;
	wssTerritory: Yup.StringSchema;
	town: Yup.StringSchema;
	endUserCategory: Yup.StringSchema;
	zipcode: Yup.StringSchema;
}

export const useTSIQuickEndMeetingFormValidation = (): ITSIQuickEndMeetingFormFields => {
	const endUserCategory = Yup.string().when('role', {
		is: RoleType.TSI,
		then: (schema) => schema.required('End User Category is required'),
		otherwise: (schema) => schema.notRequired(),
	});
	const primaryDealer = Yup.string().when('role', {
		is: RoleType.TSI,
		then: (schema) => schema.required('Primary Dealer is required'),
		otherwise: (schema) => schema.notRequired(),
	});

	const wssTerritory = Yup.string().when('role', {
		is: RoleType.TSI,
		then: (schema) => schema.required('WSS Territory is required'),
		otherwise: (schema) => schema.notRequired(),
	});

	const town = Yup.string().when('role', {
		is: RoleType.TSI,
		then: (schema) => schema.required('Town is required'),
		otherwise: (schema) => schema.notRequired(),
	});

	const zipcode = Yup.string()
		.min(6)
		.when('role', {
			is: RoleType.TSI,
			then: (schema) => schema.required('Zipcode is required'),
			otherwise: (schema) => schema.notRequired(),
		});

	return { endUserCategory, primaryDealer, wssTerritory, town, zipcode };
};
