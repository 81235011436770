export * from './quickAddEndUser';

export interface IQuickAddEndUserProps {
	setIsQuickEndModalOpen: (val: boolean) => void;
}

export interface IQuickAddEndUserForm {
	firstName: string;
	lastName: string;
	mobileNumber: string;
	endUserCategory: string;
	primaryDealer: string;
	wssTerritory: string;
	tsiTerritory: string;
	town: string;
	zipcode: string;
	city: string;
	state: string;
}

export const quickEndUserFormInitialValues: IQuickAddEndUserForm = {
	firstName: '',
	lastName: '',
	mobileNumber: '',
	endUserCategory: '',
	primaryDealer: '',
	wssTerritory: '',
	tsiTerritory: '',
	town: '',
	zipcode: '',
	city: '',
	state: '',
};

export interface IQuickAddEndUserDetails {
	id?: number;
	sfid?: string | null;
	firstname: string;
	lastname: string;
	phone: string;
	primarydealercode?: string;
	primarydealername?: string;
	wss_terri_code_c?: string;
	tsi_territory_c?: string;
	town_name_c?: string;
	town_code_c?: string;
	permanentcity_c?: string;
	permanentstate_c?: string;
	permanentzipcode_c?: string;
	segment_c?: string;
	status?: string;
	source?: string;
	referral_source_c?: string;
}
