import { ReactElement } from 'react';
import { IRadioButton } from '.';

const RadioButton = (props: IRadioButton): ReactElement => {
	const { radioOptions, name, title, checked, onChange, required, isDisabled, handleClick } = props;
	return (
		<>
			<div className="radio-group">
				<span className={`site-title h4 ${required ? 'required' : ''}`}>{title}</span>
				<div className="radio-group-wrapper">
					{radioOptions.map((radio) => {
						return (
							<div key={radio.id} className="cmn-radio">
								<input
									type="radio"
									id={radio.value}
									name={name}
									checked={radio.value === checked}
									value={radio.value}
									onChange={onChange}
									disabled={isDisabled}
									onClick={handleClick}
								/>
								<label htmlFor={radio.value}>{radio.value}</label>
							</div>
						);
					})}
				</div>
			</div>
			<p className="error-message">Please Enter the valid field</p>
		</>
	);
};

export default RadioButton;
