import { useEffect } from 'react';
import { IPermissionModel, RBAC_MODEL, Role } from '../constant';
import { decryptData } from '@helper/encryptDecrypt';
import Cookies from 'universal-cookie';
import { useRolesPermission } from '@store/rolesPermissionStore';

const getRole = async (role: string): Promise<string> => {
	return await decryptData(role);
};

export const usePermission = (): IPermissionModel[] => {
	const { permissions, setPermissions } = useRolesPermission();
	const role = new Cookies().get('role') as Role;

	useEffect(() => {
		if (permissions && permissions.length !== 0) return;
		const fetchRole = async (): Promise<void> => {
			try {
				const decryptedRole = await getRole(role);
				setPermissions(RBAC_MODEL[decryptedRole as Role]);
			} catch (error) {
				console.error('Error fetching role:', error);
			}
		};

		fetchRole();
	}, [permissions, role, setPermissions]);

	return permissions;
};
