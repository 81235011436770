import * as Sentry from '@sentry/react';
import { lazy } from 'react';
import { ErrorPage } from '@pages/ErrorPage';
import { RootLayout } from '../layouts/root-layout';
import { SubRoutingLayout } from '../layouts/sub-routing-layout';
import {
	CompetitionConversionContractor,
	CompetitionConversionGrade,
	CompetitionConversionProduct,
	CompetitionConversionUsage,
} from '@components/competition-conversion/components';
import { BasicDetailsForm, KYCDetailsForm, MoreDetailsForm } from '@components/endUserDetails/components';
import { AdoptedContractorList, AllEndUserList, LeadList } from '@components/members/components';
import { BankingDetails } from '@components/contractor360/components/contractorNavigation/bankingDetails';
import { RedemptionHistory } from '@components/contractor360/components/redemptionHistory/redemptionHistory';
import { SiteSummary } from '@components/contractor360/components/contractorNavigation/siteSummary';
import { Calendar } from '@components/calendar/calendar';
import { AddNewSite } from '@components/siteLeads/components';
import { createBrowserRouter } from 'react-router-dom';
import { AllMeetingList, ScheduledMeetingList, TodayMeetingList } from '@components/meetingModule/components';
import { AddNewMeeting } from '@components/meetingModule/components/addNewMeeting/addNewMeeting';
import { Calculator } from '@components/calculator/calculator';
import { MeetingParticipants } from '@components/meetingModule/components/meetingParticipants/meetingParticipants';
import { MeetingDetailsPage } from '@pages/MeetingDetailsPage';
import { TodayMeetingDetailsPage } from '@pages/TodayMeetingDetailsPage';
import { AttendedMembers } from '@components/todayMeetingDetails/components/attendedMembers/attendedmembers';
import { BankingHistory } from '@components/contractor360/components/bankingHistory/bankingHistory';
import { PointsHistory } from '@components/contractor360/components/pointHistory/pointHistory';
import { ConductedMeetingsPage } from '@pages/MeetingsConductedDetailsPage';
import { CompletedMeetings } from '@components/conductedMeetings/completedMeetings/completedMeetings';
import { ExpensiveDetails } from '@components/conductedMeetings/expensiveDetails/expnesiveDetails';
import { QRCodeScanner } from '@components/contractor360/components/qrScanner/qrScanner';
import { QrCodeValidate } from '@components/contractor360/components/qrScanner/components/qrCodeValidate/qrCodeValidate';
import { QRCodeCheck } from '@components/contractor360/components/qrScanner/components/qrCodeCheck/qrcodeCheck';
import { CompletedRecord } from '@components/conductedMeetings/completedRecord/completedRecord';
import { ActivityModule } from '@components/siteLeads/components/activityModule/activityModule';
import { Support } from '@pages/supportpage';
import { SiteLeadAllDetails } from '@pages/SiteLeadAllDetails';
import { AddEditDealerVisit } from '@components/delaerVisit/components/addEditDealerVisit';
import { AddEditPcmsRegistrationForm } from '@components/pcms/components/addEditPcmsRegistrationForm/addEditPcmsRegistration';
import { VisitDetailsPage } from '@pages/VisitDetails';
import { LoginPage } from '@pages/LoginPage';
import { StructuredFeedbackPage } from '@pages/StructuredFeedbackPage';
import { AddEditOneToOneMeetings } from '@components/onetoonemeetings/components/addOneToOneMeeting';
import { AdditionalDetails } from '@components/calculator/components';

const Dashboard = lazy(() =>
	import('@pages/DashboardPage').then((module) => ({
		default: module.DashboardPage,
	}))
);

const EndUsersPage = lazy(() =>
	import('@pages/EndUsersPage').then((module) => ({
		default: module.EndUsersPage,
	}))
);

const CompetitionConversion = lazy(() =>
	import('@pages/CompetitionConversionPage').then((module) => ({
		default: module.competitionConversionPage,
	}))
);
const Contractor360 = lazy(() =>
	import('@pages/Contractor360Page').then((module) => ({
		default: module.ContractorPage,
	}))
);

const EndUserDetails = lazy(() =>
	import('@pages/EndUserDetailsPage').then((module) => ({ default: module.EndUserDetailsPage }))
);

const MeetingListing = lazy(() =>
	import('@pages/MeetingModulePage').then((module) => ({ default: module.MeetingModulePage }))
);

const SiteLeads = lazy(() =>
	import('@pages/SiteLeadsPage').then((module) => ({
		default: module.SiteLeadsPage,
	}))
);

const SiteSummaryPage = lazy(() =>
	import('@pages/SiteSummaryPage').then((module) => ({
		default: module.SiteSummaryPage,
	}))
);

const Gallery = lazy(() =>
	import('@pages/GalleryPage').then((module) => ({
		default: module.GalleryModulePage,
	}))
);

const Profile = lazy(() =>
	import('@pages/ProfilePage').then((module) => ({
		default: module.ProfilePage,
	}))
);
const EndUserNorms = lazy(() =>
	import('@pages/EndUserNormsPage').then((module) => ({
		default: module.EndUserNormsPage,
	}))
);

const SiteLeadOverview = lazy(() =>
	import('@pages/SiteLeadOverview').then((module) => ({
		default: module.siteLeadOverview,
	}))
);

const DealerVisit = lazy(() =>
	import('@pages/DelaerVisitPage').then((module) => ({
		default: module.DelaerVisitPage,
	}))
);

const PCMSPage = lazy(() =>
	import('@pages/PcmsPage').then((module) => ({
		default: module.PcmsPage,
	}))
);

// TODO: Need to confirm with client for the One to one meetings module

const OneToOneMeetingsPage = lazy(() =>
	import('@pages/OneToOneMeeetingsPage').then((module) => ({
		default: module.OneToOneMeetingsPage,
	}))
);

// TODO: Need to confirm with client for the FM Content module

const FieldMarketingContent = lazy(() =>
	import('@pages/FieldMarketingContentPage').then((module) => ({
		default: module.FieldMarketingContentPage,
	}))
);

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
	{
		path: '/login',
		errorElement: <ErrorPage />,
		children: [
			{
				element: <LoginPage />,
				index: true,
			},
		],
	},
	{
		path: '/',
		element: <RootLayout />,
		errorElement: <ErrorPage />,
		children: [
			{ index: true, element: <Dashboard /> },
			{
				path: '/end-users',
				element: <EndUsersPage />,
				children: [
					{
						index: true,
						element: <AdoptedContractorList />,
					},
					{
						path: '/end-users/new-leads',
						element: <LeadList />,
					},
					{
						path: '/end-users/all',
						element: <AllEndUserList />,
					},
				],
			},
			{
				path: '/site-summary',
				element: <SiteSummaryPage />,
			},
		],
	},

	{
		path: '/profile',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				index: true,
				element: <Profile />,
			},
		],
	},

	{
		path: '/competition-conversion',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				path: '/competition-conversion',
				element: <CompetitionConversion />,
				children: [
					{
						index: true,
						element: <CompetitionConversionUsage />,
					},
					{
						path: '/competition-conversion/type-of-contractor',
						element: <CompetitionConversionContractor />,
					},
					{
						path: '/competition-conversion/grade-of-competition',
						element: <CompetitionConversionGrade />,
					},
					{
						path: '/competition-conversion/products-recommended',
						element: <CompetitionConversionProduct />,
					},
				],
			},
		],
	},

	{
		path: '/',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				path: '/meetings',
				element: <MeetingListing />,
				children: [
					{
						index: true,
						element: <ScheduledMeetingList />,
					},
					{
						path: '/meetings/todays-meetings',
						element: <TodayMeetingList />,
					},
					{
						path: '/meetings/all-meetings',
						element: <AllMeetingList />,
					},
				],
			},
			{
				path: '/dealer-visit-summary',
				element: <DealerVisit />,
			},
			{
				path: '/gallery',
				element: <Gallery />,
			},
			// TODO: Need to confirm with client for the FM Content module
			{
				path: '/field-marketing-content',
				element: <FieldMarketingContent />,
			},
			{
				path: '/pcms',
				element: <PCMSPage />,
			},
			{
				path: '/help-and-support',
				element: <Support />,
			},
			{
				path: '/one-to-one-meeting-summary',
				element: <OneToOneMeetingsPage />,
			},
		],
	},

	{
		path: '/today-meetings',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				index: true,
				element: <TodayMeetingDetailsPage />,
			},
			{
				path: '/today-meetings/attendance-details',
				element: <AttendedMembers />,
			},
		],
	},

	{
		path: '/meetings-conducted',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				path: '/meetings-conducted',
				element: <ConductedMeetingsPage />,
				children: [
					{
						index: true,
						element: <CompletedMeetings />,
					},
					{
						path: '/meetings-conducted/expense-details',
						element: <ExpensiveDetails />,
					},
					{
						path: '/meetings-conducted/completed-record',
						element: <CompletedRecord />,
					},
				],
			},
		],
	},

	{
		path: '/meeting-details',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				index: true,
				element: <MeetingDetailsPage />,
			},
			{
				path: '/meeting-details/manage-participants',
				element: <MeetingParticipants />,
			},
		],
	},

	{
		path: '/add-new-meeting',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				path: '/add-new-meeting',
				element: <AddNewMeeting />,
			},
			{
				path: '/add-new-meeting/manage-participants',
				element: <MeetingParticipants />,
			},
		],
	},

	{
		path: '/edit-meeting',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				path: '/edit-meeting',
				element: <AddNewMeeting />,
			},
			{
				path: '/edit-meeting/manage-participants',
				element: <MeetingParticipants />,
			},
		],
	},

	{
		path: '/add-member-details',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				path: '/add-member-details',
				element: <EndUserDetails />,
				children: [
					{
						path: '/add-member-details/basic-details',
						element: <BasicDetailsForm />,
					},
					{
						path: '/add-member-details/kyc-details',
						element: <KYCDetailsForm />,
					},
					{
						path: '/add-member-details/more-details',
						element: <MoreDetailsForm />,
					},
				],
			},
		],
	},

	{
		path: '/edit-member-details',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				path: '/edit-member-details',
				element: <EndUserDetails />,
				children: [
					{
						path: '/edit-member-details/basic-details',
						element: <BasicDetailsForm />,
					},
					{
						path: '/edit-member-details/kyc-details',
						element: <KYCDetailsForm />,
					},
					{
						path: '/edit-member-details/more-details',
						element: <MoreDetailsForm />,
					},
				],
			},
		],
	},

	{
		path: '/contractor',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				index: true,
				element: <Contractor360 />,
			},
			{
				path: '/contractor/banking-details',
				element: <BankingDetails />,
			},
			{
				path: '/contractor/calculator',
				element: <Calculator />,
			},
			{
				path: '/contractor/calculator/calculator-details',
				element: <AdditionalDetails />,
			},
			{
				path: '/contractor/banking-history',
				element: <BankingHistory />,
			},
			{
				path: '/contractor/points-statement',
				element: <PointsHistory />,
			},
			{
				path: '/contractor/redemption-history',
				element: <RedemptionHistory />,
			},
			{
				path: '/contractor/qr-code-scanner',
				element: <QRCodeScanner />,
			},
			{
				path: '/contractor/qr-code-check',
				element: <QRCodeCheck />,
			},
			{
				path: '/contractor/qr-code-validate',
				element: <QrCodeValidate />,
			},
			{
				path: '/contractor/site-summary',
				element: <SiteSummary />,
			},
		],
	},

	{
		path: '/calendar',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				index: true,
				element: <Calendar />,
			},
		],
	},

	{
		path: '/add-new-site',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				path: '/add-new-site',
				element: <SiteLeads />,
				children: [
					{
						index: true,
						element: <AddNewSite />,
					},
				],
			},
		],
	},

	{
		path: '/activity-module',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				index: true,
				element: <ActivityModule />,
			},
		],
	},

	{
		path: '/user-sufficiency-summary',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				index: true,
				element: <EndUserNorms />,
			},
		],
	},

	{
		path: '/site-lead-overview',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				index: true,
				element: <SiteLeadOverview />,
			},
			{
				path: '/site-lead-overview/site-details',
				element: <SiteLeadAllDetails />,
			},
			{
				path: '/site-lead-overview/visit-details',
				element: <VisitDetailsPage />,
			},
		],
	},

	{
		path: '/add-dealer-visit',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				index: true,
				element: <AddEditDealerVisit />,
			},
		],
	},

	{
		path: '/edit-dealer-visit',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				index: true,
				element: <AddEditDealerVisit />,
			},
		],
	},
	{
		path: '/add-one-to-one-meeting',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				index: true,
				element: <AddEditOneToOneMeetings />,
			},
		],
	},
	{
		path: '/edit-one-to-one-connect',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				index: true,
				element: <AddEditOneToOneMeetings />,
			},
		],
	},

	{
		path: '/add-pcms-registration',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				path: '/add-pcms-registration',
				element: <AddEditPcmsRegistrationForm />,
			},
		],
	},
	{
		path: '/edit-pcms-registration',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				path: '/edit-pcms-registration',
				element: <AddEditPcmsRegistrationForm />,
			},
		],
	},

	{
		path: '/structured-questionnaires',
		errorElement: <ErrorPage />,
		element: <SubRoutingLayout />,
		children: [
			{
				index: true,
				element: <StructuredFeedbackPage />,
			},
		],
	},
]);
