import { ReactElement, useCallback, useEffect } from 'react';
import {
	contractorDetailFormFormInitialValues,
	IContractorDetailForm,
	IContractorDetailFormProps,
	TSIContractorImageFormFields,
} from '.';
import { TextInput } from '@components/common';
import { FormikValues, useFormik } from 'formik';
import { useFormError } from '@hooks/useFormError';
import { keyDownEvent } from '@helper/utils';
import { useDebounce } from '@hooks/useDebounce';
import { usePhoneNumberValidateQuery } from '@services/hooks/enduser-details/usePhoneNumberValidateQuery';
import { ToastType, useTosterStore } from '@store/toster';
import { useValidation } from '@hooks/validations';
import { IPrimaryDealerList } from '@components/onetoonemeetings';
import { useGetRxDb } from '@hooks/getRxdbData';
import { useAddQuickEndUser } from '@services/hooks/oneToOneMeetings';
import { IQuickAddEndUserDetails } from '@components/onetoonemeetings/components/quickAddEndUser';
import { ProductImages } from '@components/siteLeads/components/siteProducts/productImages';
import { useImageUpload } from '@hooks/useImageUpload';
import { usePresignedURLQuery } from '@services/hooks/enduser-details/usePresignedURLQuery';
import { maxFileSize } from '@config/constant';

export const ContractorDetailForm = (props: IContractorDetailFormProps): ReactElement => {
	const { dealerDetails, action, setIsContractorModalOpen, contractorDetails, imageUploadCallBack } = props;
	const { result: primaryDealersList } = useGetRxDb<IPrimaryDealerList>('dealers_list');
	const { setToaster } = useTosterStore();
	const { handleAddImage } = useImageUpload();
	const { TSIContractorFormValidationSchema } = useValidation();
	const { mutate: addQuickEndUser, isPending } = useAddQuickEndUser();
	const {
		mutate: mutatePresignedURL,
		data: getSiteImagesPresignedUrl,
		isPending: isImageUploadPending,
	} = usePresignedURLQuery();

	const formik = useFormik({
		initialValues: contractorDetailFormFormInitialValues,
		onSubmit: () => {},
		validationSchema: TSIContractorFormValidationSchema,
		validate: async () => {
			const errors: Partial<FormikValues> = {};
			if (phoneValidationData?.data?.exists && phoneValidationData?.data?.message) {
				const phoneNumberError = phoneValidationData?.data?.message;
				errors.mobileNumber = phoneNumberError;
			}

			return errors;
		},
	});
	const getErrorMessage = useFormError<IContractorDetailForm>(formik);
	const {
		data: phoneValidationData,
		refetch: refetchPhoneValidation,
		isFetching: isPhoneDetailsFetching,
	} = usePhoneNumberValidateQuery(formik.values.mobileNumber);

	const handleClose = (): void => {
		setIsContractorModalOpen(false);
		if (!contractorDetails && action === 'Image Upload') {
			formik.setValues({
				...formik.values,
				productImages: [],
			});
			return;
		}
		formik.setValues({
			...formik.values,
			firstName: '',
			lastName: '',
			mobileNumber: '',
		});
		formik.setErrors({});
		formik.setTouched({});
	};

	const handleMobileNumberChange = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length === 10) {
			refetchPhoneValidation?.();
		}
	}, 100);

	const handleImageAdd = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const maxImageLimit = 1;
			const folderName = 'dealer_visit';
			const result = handleAddImage(event, formik.values.productImages, maxFileSize, maxImageLimit, folderName);
			if (result) {
				const { payload, updatedImages } = result;
				updatedImages && formik.setFieldValue('productImages', updatedImages);
				payload && mutatePresignedURL?.(payload);
			}
		},
		[formik, handleAddImage, mutatePresignedURL]
	);

	const handleSubmit = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
			e.preventDefault();
			const dealer = primaryDealersList?.find(
				(d) => d?.dealercode_c === formik.values.primaryDealer?.split('-')[1].trim()
			);
			const payload: IQuickAddEndUserDetails = {
				firstname: formik.values.firstName,
				lastname: formik.values.lastName,
				phone: formik.values.mobileNumber,
				primarydealercode: dealer?.dealercode_c,
				primarydealername: dealer?.dealername_c,
				wss_terri_code_c: dealer?.hierarchylevel1wssterritory_c,
				tsi_territory_c: dealer?.tsicode_c,
				town_name_c: dealer?.towndescription_c,
				town_code_c: dealer?.citycode_c,
				source: 'Dealer',
				referral_source_c: dealer?.dealercode_c,
			};

			const TSIImagePayload: TSIContractorImageFormFields = {
				primarydealercode: dealer?.dealercode_c,
				primarydealername: dealer?.dealername_c,
				documenturl: getSiteImagesPresignedUrl,
				documentFile: formik.values.productImages,
				source: 'Dealer',
				referral_source_c: dealer?.dealercode_c,
			};

			if (action === 'Manually') {
				addQuickEndUser?.(payload, {
					onSuccess: () => {
						setToaster(true, ToastType.success, `Lead added successfully`);
						setIsContractorModalOpen(false);
						formik.setValues({
							...formik.values,
							firstName: '',
							lastName: '',
							mobileNumber: '',
							productImages: [],
						});
						formik.setErrors({});
						formik.setTouched({});
					},
					onError: (error) => {
						setToaster(true, ToastType.error, error.message);
					},
				});
			}

			if (action === 'Image Upload' && imageUploadCallBack && !isImageUploadPending) {
				imageUploadCallBack(TSIImagePayload);
				setIsContractorModalOpen(false);
				formik.setValues({
					...formik.values,
					firstName: '',
					lastName: '',
					mobileNumber: '',
				});
			}
		},
		[
			primaryDealersList,
			formik,
			getSiteImagesPresignedUrl,
			action,
			imageUploadCallBack,
			isImageUploadPending,
			addQuickEndUser,
			setToaster,
			setIsContractorModalOpen,
		]
	);

	useEffect(() => {
		if (action === 'Image Upload' && contractorDetails) {
			const uploadDetails = contractorDetails as TSIContractorImageFormFields;
			formik.setValues({
				...formik.values,
				productImages: uploadDetails.documentFile ?? [],
			});
		}
		formik.setValues({
			...formik.values,
			primaryDealer: dealerDetails?.primaryDealer ?? '',
			wssTerritory: dealerDetails?.wssTerritory ?? '',
			tsiTerritory: dealerDetails?.tsiTerritory ?? '',
			town: dealerDetails?.town ?? '',
			action: action,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dealerDetails, action, contractorDetails]);

	return (
		<div className="quick-add-user-wrapper">
			<div className="quick-add-heading"></div>
			<form>
				<div className="quick-add-user-form">
					<ul className="row">
						{action === 'Manually' && (
							<>
								<li className="form-control d-col ">
									<TextInput
										id="firstName"
										name="firstName"
										type="text"
										onBlur={formik.handleBlur}
										value={formik.values.firstName}
										label="First Name"
										setFieldValue={formik.setFieldValue}
										error={getErrorMessage('firstName')}
										required
									/>
								</li>
								<li className="form-control d-col ">
									<TextInput
										id="lastName"
										name="lastName"
										type="text"
										onBlur={formik.handleBlur}
										value={formik.values.lastName}
										label="Last Name"
										setFieldValue={formik.setFieldValue}
										error={getErrorMessage('lastName')}
										required
									/>
								</li>
								<li className="form-control d-col ">
									<TextInput
										id="mobileNumber"
										name="mobileNumber"
										type="text"
										onBlur={formik.handleBlur}
										value={formik.values.mobileNumber}
										label="Mobile Number"
										setFieldValue={formik.setFieldValue}
										error={getErrorMessage('mobileNumber')}
										onKeyDown={keyDownEvent}
										onChange={handleMobileNumberChange}
										maxLength={10}
										required
									/>
								</li>
								<li className="form-control d-col ">
									<TextInput
										id="primaryDealer"
										name="primaryDealer"
										label="Primary Dealer"
										type="text"
										value={formik.values.primaryDealer ?? ''}
										disabled
										required
									/>
								</li>
								<li className="form-control d-col ">
									<TextInput
										id="wssTerritory"
										name="wssTerritory"
										type="text"
										value={formik.values.wssTerritory ?? ''}
										label="WSS Territory"
										disabled
										required
									/>
								</li>
								<li className="form-control d-col ">
									<TextInput
										id="tsiTerritory"
										name="tsiTerritory"
										type="text"
										value={formik.values.tsiTerritory ?? ''}
										label="TSI Territory"
										disabled
										required
									/>
								</li>
								<li className="form-control d-col ">
									<TextInput
										id="town"
										name="town"
										type="text"
										value={formik.values.town ?? ''}
										label="Town"
										disabled
										required
									/>
								</li>
							</>
						)}
						{action === 'Image Upload' && (
							<li className="d-col">
								<ProductImages
									fieldName="productImages"
									title="Contractor/Leads"
									formik={formik}
									handleAddImage={handleImageAdd}
									required
								/>
							</li>
						)}
					</ul>
					<div className="button-component">
						<button className="btn btn-primary btn-primary-2" type="button" onClick={handleClose}>
							Cancel
						</button>

						<button
							className="btn btn-primary"
							disabled={!formik.isValid || !formik.dirty || isPhoneDetailsFetching || isPending}
							type="submit"
							onClick={handleSubmit}
						>
							Submit
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};
