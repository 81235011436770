import { ReactElement, useCallback } from 'react';
import CheckTick from '@assets/images/check-circle 1.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetMainHeader } from '@hooks/useSetMainHeader';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useGetMeetingQuery } from '@services/hooks/meetings/useGetMeetingQuery';
import { useGetParticipantsQuery } from '@services/hooks/meetings/useGetParticipants';
import { DataRow } from '@helper/utils';
import { ToastType, useTosterStore } from '@store/toster';
import { ExportXlsxFile } from '@helper/exportXlsxFile';

export const CompletedRecord = (): ReactElement => {
	useSetMainHeader();
	const nav = useNavigate();
	const { state } = useLocation();
	const { setToaster } = useTosterStore();
	const { data: participants, isFetching: participantsFetching } = useGetParticipantsQuery({
		id: state,
	});
	const handleClick = useCallback((): void => {
		nav('/');
	}, [nav]);
	const { data: meetingDetails, isPending, error } = useGetMeetingQuery(state);

	const handleAttendanceDetails = (): void => {
		if (participants && participants.length > 0 && participants.filter((meet) => meet?.attendance_c).length > 0) {
			const membersData: DataRow[] = participants
				.filter((meet) => meet?.attendance_c)
				.map((item) => ({
					name: `${(item?.firstname ?? '') + ' ' + (item?.lastname ?? '')}`,
					phoneNumber: item?.phone ?? '',
					contactsfid: item.contactsfid,
					clubName: item?.clubname ?? '',
					attendance: item.attendance_c ?? '',
					contactId: item.contactid ?? '',
					repeatedAttendence: item.is_repeat_attendee_c ?? '',
					IsConverted: item.isconverted_within_5_days_c ?? '',
					meetingDate: meetingDetails?.startdate ?? '',
					MeetingName: meetingDetails?.name ?? '',
				}));
			ExportXlsxFile(membersData, meetingDetails?.name);
		} else {
			setToaster(true, ToastType.error, 'No participant data available');
		}
	};
	return (
		<section className="completed-recorded-section">
			<Wrapper isError={error} isLoaderVisible={isPending}>
				<div className="container">
					<div className="complete-recorded-wrapper">
						<div className="check-image">
							<img src={CheckTick} alt="check" title="success completed" width="200" height="200" />
						</div>

						<span className="h1 title">Congratulations</span>
						<p>You have successfully completed a meeting.</p>

						<div className="success-user-detail">
							<div className="left-content">
								<p className="user-id h3">{meetingDetails?.name}</p>
								<p className="user-number">{meetingDetails?.club_name_c}</p>
							</div>

							<div className="date h4">{meetingDetails?.enddate}</div>
						</div>

						<div className="btn-wrapper">
							<button
								type="button"
								className="btn btn-primary btn-medium"
								id="dashboard-btn"
								onClick={handleClick}
								disabled={isPending}
							>
								Go to Dashboard
							</button>

							<a
								href="/meetings/all-meetings"
								aria-label="attend-meeting"
								className="add-member"
								rel="noopener noreferrer"
								onClick={handleAttendanceDetails}
								aria-disabled={participantsFetching}
							>
								Attended Member Details
							</a>
						</div>
					</div>
				</div>
			</Wrapper>
		</section>
	);
};
