import { ReactElement } from 'react';

export * from './site-summary-card';

export enum CurrentSiteStage {
	New_Lead = 'New Lead',
	DRAFT = 'Draft',
	Site_Visited = 'Site Visited',
}

export const siteVisitedLabel = (current_stage_of_site_c: string, className = ''): ReactElement => {
	if (current_stage_of_site_c === CurrentSiteStage.New_Lead) {
		return <span className={'h5 new-lead ' + className}>{current_stage_of_site_c}</span>;
	}
	if (current_stage_of_site_c === CurrentSiteStage.DRAFT) {
		return <span className={'h5 new-lead ' + className}>{CurrentSiteStage.New_Lead}</span>;
	}
	return <span className={'site-visit h5 ' + className}>{current_stage_of_site_c}</span>;
};
