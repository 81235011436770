import { UserCard } from '../userCard/userCard';
import { ReactElement, useEffect } from 'react';
import { useFilterStore, initialFilterObject } from '@store/index';
import { EndUserFilterStore } from '@store/memberList';
import { useInfiniteScroll } from '@components/common';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useAdoptedQuery } from '@services/hooks/endusers/adoptedQuery';
import { GetMemberListData } from '@services/hooks/endusers/useMembersListRxDb';

export const AdoptedContractorList = (): ReactElement => {
	const { isFetching: queryFetch, error } = useAdoptedQuery();
	const { updateSortObject, updateFilterObject, filterObject, updateSearch, updateSortValue, updateSortActiveStatus } =
		useFilterStore((state) => state);
	const { adoptedContractorModuleFilter, updateAdoptedContractorModuleFilter } = EndUserFilterStore((state) => state);
	const { fetchMore, isFetching, result } = GetMemberListData('adopted_contractor');

	useEffect(() => {
		updateSortObject([
			{ value: 'priority', name: 'Priority Order' },
			{ value: 'glue_consumption_per_month', name: 'Glue Potential' },
			{ value: 'pwg_banking_qtd', name: 'PWG Banking in Quarter' },
		]);
		updateSortValue('');
		updateSortActiveStatus(false);
		updateSearch('');

		if (!adoptedContractorModuleFilter) {
			updateFilterObject({
				...initialFilterObject,
				dateOfVisit: { flagged: true, startDate: null, endDate: null },
				customToggle: { flagged: true, data: [{ value: 'OB' }, { value: 'ZBC' }], toggleData: [] },
				visitStatus: { flagged: true, visitStatus: '' },
				checkSecondary: [
					{
						flagged: true,
						name: 'Contractor Class',
						data: [{ value: 'XLC' }, { value: 'VLC' }, { value: 'LC' }, { value: 'RC' }, { value: 'SC' }],
						secondaryData: [],
					},
					{
						flagged: true,
						name: 'Contractor Competition Class',
						data: [{ value: 'XLC-C' }, { value: 'VLC-C' }, { value: 'LC-C' }, { value: 'RC-C' }, { value: 'SC-C' }],
						secondaryData: [],
					},
				],
				kycStatus: { flagged: true, kycStatus: '' },
				customSelect: { flagged: true, customData: [] },
			});
			return;
		}
		updateFilterObject(adoptedContractorModuleFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		updateAdoptedContractorModuleFilter(filterObject);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterObject]);

	useInfiniteScroll(() => {
		fetchMore();
	});

	return (
		<Wrapper isLoaderVisible={queryFetch || (isFetching && result.length === 0)} isError={error}>
			<div className="user-card-list-wrapper">
				<ul className="user-card-list row">
					{result.length > 0 ? (
						result.map((endUser, index) => (
							<UserCard key={endUser?._data?.id} userObject={endUser?._data} index={index} state={'/end-users'} />
						))
					) : (
						<li className="d-col d-col-2">
							<p className="h3">No Data Found!</p>
						</li>
					)}
				</ul>
			</div>
		</Wrapper>
	);
};
