import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IListAPIResponse, IQueryResponse } from '@services/api';
import { ErrorHandler } from '@helper/errorHandler';
import { BDEIMRDetail } from '@services/hooks/enduser-details/index';
export const useGetBdeImrDetails = (): IQueryResponse<IListAPIResponse<BDEIMRDetail[]>> => {
	const fetchBdeOfImr = async (): Promise<IListAPIResponse<BDEIMRDetail[]>> => {
		try {
			return await APIService.getData<IListAPIResponse<BDEIMRDetail[]>>(APICONFIG.BDE_OF_IMR);
		} catch (error) {
			ErrorHandler(error);
			throw error;
		}
	};

	const { data, error, isLoading, isPending, isFetching, refetch } = useQuery({
		queryKey: ['fetchBdeOfImr'],
		queryFn: fetchBdeOfImr,
		enabled: false,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		isFetching,
		refetch,
	};
};
