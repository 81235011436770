import { Fragment, ReactElement } from 'react';
import giftPng from '@assets/images/gift.png';
import rocketPng from '@assets/images/rocket.png';
import { ISkuSlabCombination } from '@services/hooks/calculator';

export const EarningSection = ({
	bankedKg,
	totalPoints,
	monthYearText,
	panIndiaGiftDescription,
	premierGiftDescription,
	slabCombinationOffers,
}: {
	bankedKg: number;
	totalPoints: number;
	monthYearText: string;
	panIndiaGiftDescription: string;
	premierGiftDescription?: boolean;
	worthValue: number;
	slabCombinationOffers: ISkuSlabCombination[];
}): ReactElement => {
	const roundOffValue = (value: string): number => {
		const numStr = value.toString();
		const [integerPart, decimalPart] = numStr.split('.');
		const integer = parseInt(integerPart);
		const decimal = parseFloat('0.' + (decimalPart || 0));
		const rounded = decimal > 0.5 ? integer + 1 : integer;
		return rounded;
	};

	return (
		<div className="earning-wrapper">
			<div className="title cm-heading">
				<div className="inner-content">Total Earnings</div>
			</div>

			<div className="earning-wrapper-section">
				<span className="earning-title">
					<span>Total Points Achieved for {bankedKg.toFixed(2)} Kg &nbsp;</span>
					<span className="month">({monthYearText})</span>
				</span>

				<div className="earning-point bg-round">{roundOffValue(totalPoints.toFixed(2))} Points</div>

				{panIndiaGiftDescription && (
					<Fragment>
						<div className="plus">
							<span className="icon-plus"></span>
						</div>
						<div className="gift-box">
							<div className="gift-content">
								<p className="content-title">{panIndiaGiftDescription}</p>
								<span className="month">({monthYearText})</span>
							</div>
							<div className="gift-image bg-round">
								<img src={giftPng} alt="gift" title="gift" width="40" height="40" />
							</div>
						</div>
					</Fragment>
				)}
				{premierGiftDescription && (
					<Fragment>
						<div className="plus">
							<span className="icon-plus"></span>
						</div>

						<div className="trip-box">
							<div className="trip-image bg-round">
								<img src={rocketPng} alt="roket" title="roket" width="40" height="40" />
							</div>

							<div className="trip-box-content">
								<p className="box-title">Fevicol Premier Circle rewards basis slab qualification </p>
								<p className="value-month">({monthYearText})</p>
								{/* for future use */}
								{/* <p>
								<span className="town">Paris</span> <span className="worth">worth</span>{' '}
								<span className="value">₹5L</span>{' '}
							</p>
							<p className="value-month">at 1200 Kg at FPC ({monthYearText})</p> */}
							</div>
						</div>
					</Fragment>
				)}

				{slabCombinationOffers?.map((offer) => {
					if (
						offer?.bonus_type === 'Gift' &&
						offer?.kgValueSum &&
						offer?.kgValueSum >= offer?.slab_from &&
						offer?.kgValueSum <= offer?.slab_to
					) {
						return (
							<Fragment key={offer?.id}>
								<div className="plus">
									<span className="icon-plus"></span>
								</div>
								<div className="gift-box">
									<div className="gift-content">
										<p className="content-title">{offer?.gift_description}</p>
									</div>
									<div className="gift-image bg-round">
										<img src={giftPng} alt="gift" title="gift" width="40" height="40" />
									</div>
								</div>
							</Fragment>
						);
					}
				})}
			</div>
		</div>
	);
};
