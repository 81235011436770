import { ReactElement, useEffect } from 'react';
import { UserCard } from '../userCard/userCard';
import { useFilterStore, initialFilterObject } from '@store/index';
import { EndUserFilterStore } from '@store/memberList';
import { useInfiniteScroll } from '@components/common';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { useLeadUserQuery } from '@services/hooks/endusers/leadUserQuery';
import { useCloseLeadsPopup } from '@store/closeLeadsPopupStore';
import { CloseLeadsModal } from '@components/common/close-leads/closeLeadsPopUp';
import { Modal } from '@components/common/modal/modal';
import { GetMemberListData } from '@services/hooks/endusers/useMembersListRxDb';

export const LeadList = (): ReactElement => {
	const { isOpen, setModal } = useCloseLeadsPopup((state) => state);
	const { isFetching: queryFetching, error } = useLeadUserQuery();
	const { updateSortObject, updateFilterObject, filterObject, updateSearch, updateSortValue, updateSortActiveStatus } =
		useFilterStore((state) => state);
	const { leadUserModuleFilter, updateLeadUserModuleFilter } = EndUserFilterStore((state) => state);
	const { fetchMore, isFetching, result } = GetMemberListData('new_leads');

	useEffect(() => {
		updateSortObject([
			{ value: 'name', name: 'Name' },
			{ value: 'createddate', name: 'Create Date' },
		]);
		updateSortValue('');
		updateSortActiveStatus(false);
		updateSearch('');

		if (leadUserModuleFilter === null) {
			updateFilterObject({
				...initialFilterObject,
				dateOfVisit: { flagged: true, startDate: null, endDate: null },
				singleDropDown: {
					flagged: true,
					name: 'Source',
					data: [
						{ value: 'Dealer', id: 1 },
						{ value: 'FCC', id: 2 },
						{ value: 'BDE', id: 3 },
						{ value: 'IMR', id: 4 },
					],
					dropDownData: '',
				},
			});
			return;
		}
		updateFilterObject(leadUserModuleFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		updateLeadUserModuleFilter(filterObject);
	}, [filterObject, updateLeadUserModuleFilter]);

	useInfiniteScroll(() => {
		fetchMore();
	});

	return (
		<>
			<Wrapper isLoaderVisible={queryFetching || (isFetching && result.length === 0)} isError={error}>
				<div className="user-card-list-wrapper">
					<ul className="user-card-list row">
						{result.length > 0 ? (
							result.map((endUser, index) => {
								return (
									<UserCard key={endUser?._data?.id} userObject={endUser?._data} index={index} state={'/new-leads'} />
								);
							})
						) : (
							<li className="d-col d-col-2">
								<p className="h3">No Data Found!</p>
							</li>
						)}
					</ul>
				</div>
			</Wrapper>
			<Modal
				modalOpen={isOpen}
				modalId={'closeLeads'}
				onModalClose={() => {
					setModal(false);
				}}
				className="dialog-sm close-site-popup dialog-conform"
			>
				<CloseLeadsModal />
			</Modal>
		</>
	);
};
