import { useRxData } from 'rxdb-hooks';
import { escapeRegExp, getMonthNumber } from '@helper/utils';
import { RxCollection } from 'rxdb';
import { useCallback } from 'react';
import { MeetingsFilter, MeetingsObject } from '..';
import { useDebounceSearch } from '@hooks/useDebounceSearch';
import { IMeetingReturn, MeetingFilterObject } from '.';
import { useFilterStore } from '@store/filter';
import { useRxCountQuery } from '@hooks/useCount';

export const GetMeetings = (filter: MeetingFilterObject): IMeetingReturn => {
	const { search } = useFilterStore();
	const debouncedSearch = useDebounceSearch(search);

	const queryConstructor = useCallback(
		(collection: RxCollection<MeetingsObject>) => {
			const selector: MeetingsFilter = {};

			if (debouncedSearch && debouncedSearch.trim() !== '') {
				selector.name = { $regex: `.*${escapeRegExp(debouncedSearch)}.*`, $options: 'i' };
			}

			if (filter.type) {
				selector.status = { $eq: filter.type };
			}
			const [startDate, endDate] = filter.filter_date || [];
			const meetingStatus = filter.meetingStatus?.map((item) => item.value) || [];
			const clubData = filter.club?.map((item) => item.value) || [];

			if (startDate && endDate) {
				selector.startdate = {
					$gte: startDate.toISOString(),
					$lte: endDate.toISOString(),
				};
			}

			if (meetingStatus.length > 0) {
				selector.status = { $in: meetingStatus };
			}

			if (clubData.length > 0) {
				selector.club_name_c = {
					$regex: `.*${escapeRegExp(clubData.join(','))}.*`,
					$options: 'i',
				};
			}

			if (filter.month) {
				selector.startdate = {
					...selector.startdate,
					$regex: `-${getMonthNumber(filter.month)}-`,
				};
			}

			if (filter.meetingType) {
				selector.meeting_type_c = { $eq: filter.meetingType };
			}
			if (filter.meetingTheme) {
				selector.meeting_theme_c = { $eq: filter.meetingTheme };
			}

			(Object.keys(selector) as Array<keyof MeetingsFilter>).forEach((key) => {
				if (selector[key] === undefined || selector[key] === null) {
					delete selector[key];
				}
			});

			return collection
				.find({
					selector: Object.keys(selector).length > 0 ? selector : {},
				})
				.sort({ startdate: 'desc' });
		},
		[filter, debouncedSearch]
	);
	const totalCount = useRxCountQuery<MeetingsObject>('meeting_list', queryConstructor);

	const { result, isFetching, fetchMore } = useRxData<MeetingsObject>('meeting_list', queryConstructor, {
		pageSize: 20,
		pagination: 'Infinite',
	});

	return { result, isFetching, fetchMore, totalCount };
};
