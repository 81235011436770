import { APICONFIG } from '@config/api.config';
import { IQueryResponse } from '@services/api';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { ValidatePhoneNumberData } from '.';

export const usePhoneNumberValidateQuery = (
	phoneNumber?: string | null,
	fieldName?: string
): IQueryResponse<ValidatePhoneNumberData> => {
	const fetchPhoneNumberValidateData = async (): Promise<ValidatePhoneNumberData> => {
		return await APIService.getData<ValidatePhoneNumberData>(APICONFIG.PHONE_NUMBER, {
			phone_number: phoneNumber || '',
		});
	};

	const { data, error, isLoading, isPending, refetch } = useQuery({
		queryKey: ['validateNumber', phoneNumber, fieldName],
		queryFn: fetchPhoneNumberValidateData,
		enabled: false,
		gcTime: 0,
		staleTime: 0,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
	};
};
