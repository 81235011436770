import { useRxCollection, useRxQuery } from 'rxdb-hooks';
import { RxCollection, RxQuery, RxDocument } from 'rxdb/dist/types/types';
import { useMemo } from 'react';

type QueryConstructor<T> = (collection: RxCollection<T>) => RxQuery<T, RxDocument<T, object>[]>;

export function useRxCountQuery<T>(collectionName: string, queryConstructor: QueryConstructor<T>): number {
	const collection = useRxCollection<T>(collectionName);

	const countQuery = useMemo(() => {
		if (!collection) return undefined;
		return queryConstructor(collection).limit(0);
	}, [collection, queryConstructor]);

	// Use the count query
	const { result: countResult } = useRxQuery(countQuery);

	// Calculate the total count
	const totalCount = useMemo(() => {
		if (!countResult) return 0;
		return countResult.length;
	}, [countResult]);

	return totalCount;
}
