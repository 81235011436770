import { DropdownOptions } from '@components/common/formControl/dropdown';

export interface Question {
	id: number;
	type: string;
	fieldName: string;
	question: string;
	options: DropdownOptions[];
	placeHolder: string;
	validation?: string;
}

export interface IMasterFeedbackQuestions {
	[key: string]: Question[];
}

export interface IAnalyticsFeedbackQuestions {
	[key: string]: {
		id: number;
		type: string;
		placeHolder: string;
		fieldName: string;
		question: string;
		options: {
			id: number;
			value: string;
		}[];
	}[];
}

export interface IFeedbackQuestions {
	analytics: IAnalyticsFeedbackQuestions;
	master: IMasterFeedbackQuestions;
}

export interface IMasterFeedbackStaticQuestions {
	[key: string]: {
		id: number;
		question: string;
	}[];
}

export const masterFeedbackStaticQuestion: IMasterFeedbackStaticQuestions = {
	'FV Disgruntled': [
		{
			id: 1,
			question: 'What is the reason for FV disgruntlement?',
		},
	],
};

export const analyticsFeedbackQuestions: IAnalyticsFeedbackQuestions = {
	'1': [
		{
			id: 1,
			fieldName: '',
			type: 'dropdown',
			placeHolder: '',
			question: '',
			options: [
				{ id: 1, value: 'Yes' },
				{ id: 2, value: 'No' },
			],
		},
	],
	'2': [
		{
			id: 1,
			type: 'dropdown',
			placeHolder: '',
			fieldName: '',
			question: '',
			options: [
				{ id: 1, value: 'No ongoing sites for product usage' },
				{ id: 2, value: 'Homeowner/ architect not convinced' },
				{ id: 3, value: 'The price of product is high' },
				{ id: 4, value: 'Wasn’t happy with product performance after demo' },
				{ id: 5, value: 'Product unavailable at dealer point' },
				{ id: 6, value: 'Others' },
			],
		},
	],
	'3': [
		{
			id: 1,
			type: 'dropdown',
			placeHolder: '',
			fieldName: '',
			question: '',
			options: [
				{ id: 1, value: 'Using a competition product for same purpose' },
				{ id: 2, value: 'Using a Pidilite product for same purpose' },
				{ id: 3, value: 'Price of product is high' },
				{ id: 4, value: 'Not relevant since he doesn’t use these substrates' },
				{ id: 5, value: 'Quality issue with product' },
				{ id: 6, value: 'Difficult application' },
				{ id: 7, value: 'Others' },
			],
		},
	],
	'4': [
		{
			id: 1,
			type: 'dropdown',
			placeHolder: '',
			fieldName: '',
			question: '',
			options: [
				{ id: 1, value: 'Yes' },
				{ id: 2, value: 'No' },
			],
		},
	],
	'5': [
		{
			id: 1,
			type: 'dropdown',
			placeHolder: '',
			fieldName: '',
			question: '',
			options: [
				{ id: 1, value: 'No ongoing sites for product usage' },
				{ id: 2, value: 'Homeowner/ architect not convinced' },
				{ id: 3, value: 'The price of product is high' },
				{ id: 4, value: 'Wasn’t happy with product performance after demo' },
				{ id: 5, value: 'Product unavailable at dealer point' },
				{ id: 6, value: 'Others' },
			],
		},
	],
	'6': [
		{
			id: 1,
			type: 'dropdown',
			placeHolder: '',
			fieldName: '',
			question: '',
			options: [
				{ id: 1, value: 'Using a competition product for same purpose ' },
				{ id: 2, value: 'Using a Pidilite product for same purpose' },
				{ id: 3, value: 'Current scheme/ FCCR point on product is not attractive' },
				{ id: 4, value: 'Quality issue with product' },
				{ id: 5, value: 'Slow business / fewer sites' },
				{ id: 6, value: 'Substrate usage has decreased' },
				{ id: 7, value: 'Others' },
			],
		},
	],
	'7': [
		{
			id: 1,
			type: 'dropdown',
			placeHolder: '',
			fieldName: '',
			question: '',
			options: [
				{ id: 1, value: 'Using a competition product for same purpose' },
				{ id: 2, value: 'Using a Pidilite product for same purpose' },
				{ id: 3, value: 'Current scheme/ FCCR point on product is not attractive' },
				{ id: 4, value: 'Quality issue with product' },
				{ id: 5, value: 'Slow business / fewer sites' },
				{ id: 6, value: 'Substrate usage has decreased' },
				{ id: 7, value: 'Others' },
			],
		},
	],
	'8': [
		{
			id: 1,
			type: 'dropdown',
			placeHolder: '',
			fieldName: '',
			question: '',
			options: [
				{ id: 0, value: 'Yes' },
				{ id: 1, value: 'No' },
			],
		},
	],
};

export const masterFeedbackQuestions: IMasterFeedbackQuestions = {
	'Lower price of competition product': [
		{
			id: 1,
			type: 'dropdown',
			fieldName: '',
			question: '',
			options: [],
			placeHolder: '',
		},
	],

	'Better schemes of competition product': [
		{
			id: 1,
			type: 'dropdown',
			fieldName: '',
			question: 'How are the competition schemes better?',
			options: [
				{ id: 0, value: 'He gets instant gift on purchasing drum' },
				{ id: 1, value: 'He gets instant cash on purchasing drum' },
				{ id: 2, value: 'He gets better gifts than Fevicol gives him' },
			],
			placeHolder: '',
		},
	],

	'Competition due to dealer push': [
		{
			id: 1,
			type: 'dynamic-dropdown',
			fieldName: '',
			question: 'Which dealer has the adhesive been bought from?',
			options: [],
			placeHolder: 'Dealer Code',
		},
	],

	'Homeowner bought cheaper adhesive': [
		{
			id: 1,
			type: 'text',
			fieldName: '',
			question: 'Homeowner Name',
			options: [],
			placeHolder: 'Name',
			validation: 'text',
		},
		{
			id: 2,
			type: 'text',
			fieldName: '',
			question: 'Homeowner Number',
			options: [],
			placeHolder: 'Phone Number',
			validation: 'number',
		},
		{
			id: 3,
			type: 'dynamic-dropdown',
			fieldName: '',
			question: 'Dealer Details',
			options: [],
			placeHolder: 'Dealer Code',
		},
		{
			id: 4,
			type: 'checkbox',
			fieldName: '',
			question: 'Provision to request for Joint Visit with F&B Team',
			options: [],
			placeHolder: '',
		},
	],

	'FV Disgruntled': [
		{
			id: 1,
			fieldName: '',
			placeHolder: '',
			type: 'dropdown',
			question: 'What is the reason for FV disgruntlement?',
			options: [
				{ id: 1, value: 'Product Quality Issue' },
				{ id: 2, value: 'Scheme Settlement' },
				{ id: 3, value: 'Product Availability' },
			],
		},

		// Commented for the future CR Scope

		// {
		// 	id: 2,
		// 	type: 'text',
		// 	fieldName: '',
		// 	question: 'Product Quality Issue',
		// 	options: [],
		// 	placeHolder: '',
		// },
		// {
		// 	id: 3,
		// 	type: 'text',
		// 	fieldName: '',
		// 	question: 'Scheme Settlement',
		// 	options: [],
		// 	placeHolder: '',
		// },
		// {
		// 	id: 4,
		// 	type: 'text',
		// 	fieldName: '',
		// 	question: 'Product Availability',
		// 	options: [],
		// 	placeHolder: '',
		// },
	],

	'Superior quality of competition': [
		{
			id: 1,
			type: 'text',
			fieldName: '',
			question: 'How is the competition product superior to FV products?',
			options: [],
			placeHolder: '',
			validation: 'text',
		},
	],

	'Builder choses adhesive': [
		{
			id: 1,
			type: 'dynamic-dropdown',
			fieldName: '',
			question: 'Dealer Details',
			options: [],
			placeHolder: 'Dealer Code',
		},
		{
			id: 2,
			type: 'text',
			fieldName: '',
			question: 'Builder Name',
			options: [
				{ id: 1, value: 'Builder Contact No' },
				{ id: 2, value: 'Provision to request Joint visit with F&B team' },
			],
			placeHolder: 'Name',
			validation: 'text',
		},
		{
			id: 3,
			type: 'text',
			fieldName: '',
			question: 'Builder Contact No',
			options: [],
			placeHolder: 'Number',
			validation: 'number',
		},
		{
			id: 4,
			type: 'checkbox',
			fieldName: '',
			question: 'Provision to request Joint visit with F&B team',
			options: [],
			placeHolder: '',
		},
	],

	fv: [
		{
			id: 1,
			type: 'dropdown',
			fieldName: '',
			question: '',
			options: [],
			placeHolder: '',
		},
	],

	fnb: [
		{
			id: 1,
			type: 'dropdown',
			fieldName: '',
			question: 'Reason for F&B being used on site instead of FV?',
			options: [
				{ id: 0, value: 'F&B schemes being higher than FV' },
				{ id: 1, value: 'Dealer pushing F&B products instead of Fevicol products' },
				{ id: 2, value: 'Disgruntlement/ unresolved issues with Fevicol' },
				{ id: 3, value: 'Stock issues with Fevicol leading to F&B usage' },
				{ id: 4, value: 'F&B product quality better than Fevicol' },
			],
			placeHolder: '',
		},
	],
};

export const exceptionFeedbackQuestions: IAnalyticsFeedbackQuestions = {
	'1': [
		{
			id: 1,
			fieldName: '',
			placeHolder: '',
			type: 'dropdown',
			question: '',
			options: [
				{ id: 1, value: 'User has switched to competition' },
				{ id: 2, value: 'Slow business/ fewer sites' },
				{ id: 3, value: 'His carpenter is banking his points' },
				{ id: 4, value: 'User disgruntled with FV' },
				{ id: 5, value: 'User not banking points' },
				{ id: 6, value: 'Others' },
			],
		},
	],

	'2': [
		{
			id: 2,
			fieldName: '',
			placeHolder: '',
			type: 'dropdown',
			question: '',
			options: [
				{
					id: 1,
					value: 'Accumulating points for a big redemption',
				},
				{ id: 2, value: 'Stock unavailable for the gift he wants to redeem' },
			],
		},
	],

	'3': [
		{
			id: 3,
			fieldName: '',
			placeHolder: '',
			type: 'dropdown',
			question: '',
			options: [
				{
					id: 1,
					value: 'Prefers EGVs due to gift variety',
				},
				{ id: 2, value: 'Prefers EGVs due to home delivery option' },
				{
					id: 3,
					value: 'Stock unavailable for the gifts he wants to redeem in RC',
				},
			],
		},
	],

	'4': [
		{
			id: 4,
			fieldName: '',
			placeHolder: '',
			type: 'dropdown',
			question: '',
			options: [
				{
					id: 0,
					value: 'Using a competition product for same purpose',
				},
				{ id: 1, value: 'Using a Pidilite product for same purpose' },
				{
					id: 2,
					value: 'Current scheme/ FCCR point on product is not attractive',
				},
				{
					id: 3,
					value: 'Quality issue with product',
				},
				{
					id: 4,
					value: 'Slow business / fewer sites',
				},
				{
					id: 5,
					value: 'Substrate usage has decreased',
				},
				{
					id: 6,
					value: 'Others',
				},
			],
		},
	],
};

export const feedbackQuestions: IFeedbackQuestions = {
	analytics: analyticsFeedbackQuestions,
	master: masterFeedbackQuestions,
};
