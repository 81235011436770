import { MouseEvent, ReactElement, useCallback, useEffect, useRef } from 'react';
import { Sidebar } from '@components/common';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { overlayAddFadeAction } from '@components/common/sort';
import { useSiderToggleMenu } from '@store/useSiderToggleMenu';
import { useRefetchAllData, UseSyncData } from '@helper/fetchData';
import { meetingPaths, presetRoutePath } from '@components/common/header/index';

export type IHeaderProps = {
	subRoute?: boolean;
	menuHeading?: string;
};
export const Header = (props: IHeaderProps): ReactElement => {
	const currentBackIndex = useRef<number>(0);
	const currentRouteName = useRef<string>('');
	const { setModal } = useSiderToggleMenu();
	const refreshButtonRef = useRef<HTMLButtonElement>(null);
	UseSyncData();
	const { refetchAllData } = useRefetchAllData();
	const { subRoute, menuHeading } = props;
	const navigate = useNavigate();
	const { pathname } = useLocation();

	useEffect(() => {
		if (currentRouteName.current === menuHeading) return;

		if (presetRoutePath.find((path) => path.currentPath === pathname)) {
			currentBackIndex.current++;
			currentRouteName.current = menuHeading ?? '';
		}
	}, [menuHeading, pathname]);

	const goBack = (): void => {
		const path = presetRoutePath.find((path) => path.currentPath === pathname);
		if (window.history.length == 2 || meetingPaths.includes(pathname)) {
			navigate('/');
			return;
		}
		if (path) {
			navigate(-currentBackIndex.current);
			currentBackIndex.current = 0;
			currentRouteName.current = '';
			return;
		}
		if (window.history.length > 1) {
			navigate(-1);
		}
	};

	const refetchSync = useCallback(
		(e: MouseEvent<HTMLButtonElement>): void => {
			e.preventDefault();
			refetchAllData?.();
			if (refreshButtonRef.current) {
				refreshButtonRef.current.classList.add('spin-animation');
				setTimeout(() => {
					if (refreshButtonRef.current) {
						refreshButtonRef.current.classList.remove('spin-animation');
					}
				}, 3000);
			}
		},
		[refetchAllData]
	);

	useEffect(() => {
		const intervalId = setInterval(
			() => {
				refetchAllData?.();
			},
			60 * 60 * 1000
		);

		return (): void => clearInterval(intervalId);
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<header className="top-header-wrapper">
				<div className="container">
					<div className="top-header">
						<div className="sidebar-toggle">
							{!subRoute && (
								<button
									type="button"
									aria-label="toggle-button"
									onClick={() => overlayAddFadeAction(() => setModal(true), 'sidebar-overlay')}
									className="navbar-btn"
									value="toggle-button"
								>
									<span className="icon-menu"></span>
								</button>
							)}
							{subRoute && (
								<button
									onClick={goBack}
									type="button"
									aria-label="back-button"
									className="navbar-btn hide"
									value="back-button"
								>
									<span className="icon-arrow-left"></span>
								</button>
							)}
						</div>
						<span id="main-header" className="h3">
							{menuHeading ?? 'MPower'}
						</span>
						<div className="header-calendar-wrapper">
							<button
								type="button"
								ref={refreshButtonRef}
								aria-label="refresh"
								className="refresh-btn"
								onClick={refetchSync}
							>
								<span className="icon-refresh-cw" />
							</button>

							{!subRoute && (
								<div className="header-calender">
									<NavLink to="/calendar" aria-label="Go to calender">
										<span className="icon-calendar"></span>
									</NavLink>
								</div>
							)}
						</div>
					</div>
				</div>
			</header>
			<Sidebar />
		</>
	);
};
