import { ReactElement, useCallback, useRef } from 'react';
import { useSiteLeadStore } from '@store/siteLeadStore';
import { Checkbox, DealerDropdownOption, Dropdown, TextInput } from '@components/common';
import { IFeedback, IProductFeedbackProps } from '.';
import { onImageError } from '@helper/utils';
import { useOutsideDialogClose } from '@hooks/useOutsideDialogClose';
import { NavLink } from 'react-router-dom';

export const ProductFeedback = (props: IProductFeedbackProps): ReactElement => {
	const { formik, products } = props;
	const dialogRef = useRef<HTMLDivElement | null>(null);
	const { glue, setFeedbackModal, feedbackResponseValue, productIndex, setOwnerDetails, setDealerName, setProductId } =
		useSiteLeadStore();

	useOutsideDialogClose({
		dialogRef,
		callBackFunction: () => setFeedbackModal(false),
	});
	const product = products?.[productIndex];

	const getDetails = useCallback(() => {
		const feedback = formik.values.feedback[productIndex] || [];
		const details: {
			ownerName?: string;
			ownerNumber?: string;
			dealerDetails?: string;
		} = {};

		feedback.forEach((val: IFeedback) => {
			switch (val.feedbackQuestion) {
				case 'Homeowner Name':
					details.ownerName = val.feedbackResponse;
					break;
				case 'Homeowner Number':
					details.ownerNumber = val.feedbackResponse;
					break;
				case 'Dealer Details':
					details.dealerDetails = val.feedbackResponse;
					break;
			}
		});
		return details;
	}, [formik.values.feedback, productIndex]);

	const handleFeedbackSubmit = useCallback((): void => {
		const { ownerName, ownerNumber, dealerDetails } = getDetails();

		ownerName && ownerNumber && setOwnerDetails(ownerName, ownerNumber);
		dealerDetails && setDealerName(dealerDetails);

		formik.setFieldValue(`productResponse[${productIndex}]`, feedbackResponseValue);
		setFeedbackModal(false);
	}, [feedbackResponseValue, formik, getDetails, productIndex, setDealerName, setFeedbackModal, setOwnerDetails]);

	const handleCheckboxChange = useCallback(
		(checkboxId: string, currentProductIndex: number): void => {
			const currentValue = formik.values.feedback[productIndex][currentProductIndex]?.feedbackResponse;
			formik.setFieldValue(checkboxId, !currentValue);
		},
		[formik, productIndex]
	);

	const handleActivityClick = (): void => {
		product && setProductId(product?.id);
	};
	return (
		<div ref={dialogRef} className="popup-content">
			<div className="product-details">
				<div className="product-content">
					<div className="product-image-wrapper">
						<img
							src={product?.image ?? ''}
							alt="product"
							title="product"
							width="82"
							height="100"
							onError={onImageError}
						/>
					</div>

					<div className="date-details">
						<span className="product-title">
							<span className="h3">{product?.product_name}</span>
							{productIndex === 0 && glue === 'Competition' && <span className="conversion-tag">{glue}</span>}
						</span>

						<span className="date-about">
							<span>
								<span>
									Last Date of Demo:{' '}
									<b>{product?.demo_date && product?.demo_date !== 'null' ? product?.demo_date : 'N/A'}</b>
								</span>
								<span>
									Last Date of Purchase:{' '}
									<b>
										{product?.discussed_date && product?.discussed_date !== 'null' ? product?.discussed_date : 'N/A'}
									</b>
								</span>
							</span>

							<NavLink
								to="/activity-module"
								target="_blank"
								aria-label="activity module url"
								className="activity-module"
								onClick={handleActivityClick}
							>
								<span className="icon-module icon"></span>
								<span className="module-name">Activity Module</span>
							</NavLink>
						</span>
					</div>
				</div>

				{/* Commented for the future CR Scope */}

				{/* {reason &&
					masterFeedbackStaticQuestion[reason]?.map((item) => (
						<div key={item?.id} className="h4">
							{item?.question}
						</div>
					))} */}
				{formik.values.feedback[productIndex]?.map((question: IFeedback, index: number) => {
					const id = `feedback[${productIndex}][${index}].feedbackResponse`;
					return (
						<div key={question.id} className="about-question">
							{question.type !== 'checkbox' && <span className="title">{question.feedbackQuestion}</span>}

							{((): false | JSX.Element | null => {
								switch (question.type) {
									case 'text':
										return (
											<div className="input-details-wrapper form-input">
												<div className="input-details">
													<TextInput
														id={id}
														name={id}
														type="text"
														onBlur={formik.handleBlur}
														value={formik.values.feedback[productIndex][index]?.feedbackResponse ?? ''}
														setFieldValue={formik.setFieldValue}
														placeholder={question.placeHolder}
														error={
															formik.touched.feedback?.[productIndex]?.[index]?.feedbackResponse
																? formik.errors.feedback?.[productIndex]?.[index]?.feedbackResponse
																: null
														}
													/>
												</div>
											</div>
										);
									case 'checkbox':
										return (
											<Checkbox
												id={id}
												name={id}
												type="checkbox"
												checked={formik.values.feedback[productIndex][index]?.feedbackResponse || false}
												onChange={() => handleCheckboxChange(id, index)}
												label="Joint visit with F&amp;B team"
												className="join-team"
											/>
										);
									case 'dropdown':
										return (
											<Dropdown
												id={id}
												name={id}
												onBlur={formik.handleBlur}
												title={formik.values.feedback[productIndex][index].feedbackResponse ?? ''}
												error={
													formik.touched.feedback?.[productIndex]?.[index]?.feedbackResponse
														? formik.errors.feedback?.[productIndex]?.[index]?.feedbackResponse
														: null
												}
												options={question.feedbackOptions}
												placeholder={question.placeHolder}
												setFieldValue={formik.setFieldValue}
											/>
										);
									case 'dynamic-dropdown':
										return (
											<DealerDropdownOption
												id={id}
												name={id}
												onBlur={formik.handleBlur}
												title={formik.values.feedback[productIndex][index].feedbackResponse ?? ''}
												error={
													formik.touched.feedback?.[productIndex]?.[index]?.feedbackResponse
														? formik.errors.feedback?.[productIndex]?.[index]?.feedbackResponse
														: null
												}
												placeholder={question.placeHolder}
												setFieldValue={formik.setFieldValue}
											/>
										);
									default:
										return null;
								}
							})()}
						</div>
					);
				})}
			</div>

			<div className="submit-button">
				<button
					type="button"
					className="btn btn-primary btn-medium"
					disabled={formik.errors?.feedback?.[productIndex]}
					onClick={handleFeedbackSubmit}
				>
					Submit
				</button>
			</div>
		</div>
	);
};
