import { ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ISidebarConfig } from '@config/sidebar.config';
import { useComponentLevelAccessCheck } from '../../../authGuard/hooks/useComponentLevelAccessCheck';
import { useGetRxDb } from '@hooks/getRxdbData';
import { ProfileData } from '@services/hooks/profile';
import { RoleType } from '@helper/utils';

export interface IMenuItemProps {
	menu: ISidebarConfig;
	toggleMenu: () => void;
}

export const MenuItem = (props: IMenuItemProps): ReactElement => {
	const location = useLocation();
	const { menu, toggleMenu } = props;
	const accessCheck = useComponentLevelAccessCheck(menu.route, true);
	const { result: profileData } = useGetRxDb<ProfileData>('profile');

	const isActiveRoute = (route: string): boolean => {
		if (route === '/') {
			return location.pathname === '/';
		}
		return location.pathname.startsWith(route);
	};

	if (!accessCheck) return <></>;

	const getAriaLabel = (): string => {
		if (typeof menu.ariaLabel === 'string') {
			return menu.ariaLabel;
		}

		const userType = profileData[0]?.usertype_c;

		switch (userType) {
			case RoleType.TSI:
				return menu.ariaLabel.TSI ?? menu.ariaLabel.default;
			case RoleType.IMR:
				return menu.ariaLabel.IMR ?? menu.ariaLabel.default;
			default:
				return menu.ariaLabel.default;
		}
	};

	return (
		<li key={getAriaLabel()}>
			<NavLink
				to={menu.route}
				className={isActiveRoute(menu.route) ? 'active' : ''}
				end
				aria-label={getAriaLabel()}
				onClick={toggleMenu}
			>
				<span className={menu.icon} /> {getAriaLabel()}
				<span className="icon-arrow-right-circle"></span>
			</NavLink>
		</li>
	);
};
