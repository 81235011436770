import { ReactElement } from 'react';
import '@assets/styles/pages/login-page.scss';
import { Navigate } from 'react-router-dom';
import { useRouteHandler } from '../authGuard';
import { LoginFormPage } from '@components/login/loginFormPage';

export const LoginPage = (): ReactElement => {
	const { isAuthenticated } = useRouteHandler();

	if (process.env.REACT_APP_LOCAL_LOGIN === 'true') {
		return (
			<>
				<LoginFormPage />
				<div id="overlay" className="overlay"></div>
			</>
		);
	}

	if (!isAuthenticated()) {
		window.location.href = process.env.REACT_APP_FV_REDIRECTION_URL ?? '/';
		return <></>;
	}

	return <Navigate to="/" replace />;
};
