import { APICONFIG } from '@config/api.config';
import { APIService } from '@services/api/common-services';
import { useQuery } from '@tanstack/react-query';
import { IQueryResponse } from '@services/api';
import { ProfileData } from '.';
import { setRxdb } from '@helper/utils';
import { useCheckRxdb } from '@hooks/checkRxdb';
import { clearAllCookies } from '@helper/cookiesClear';

export const useProfileQuery = (): IQueryResponse<ProfileData> => {
	const { isDbChecked, shouldFetch } = useCheckRxdb('profile');
	const fetchProfileData = async (): Promise<ProfileData> => {
		const response = await APIService.getData<ProfileData>(APICONFIG.PROFILE_API);
		if (response) {
			if (response.user_division_name_c !== 'WWA') {
				clearAllCookies();
				alert('You are not allowed to access the website!');
				window.location.reload();
				return response;
			}
			await setRxdb('profile', [response]);
		}
		return response;
	};

	const { data, error, isLoading, isPending, refetch } = useQuery({
		queryKey: ['profile'],
		queryFn: fetchProfileData,
		enabled: isDbChecked && shouldFetch,
		refetchOnWindowFocus: false,
		retry: false,
	});

	return {
		data,
		error,
		isLoading,
		isPending,
		refetch,
	};
};
