import * as Yup from 'yup';
import { CreateContractorNameValidation } from '.';
import { RoleType } from '@helper/utils';

export const contractorNameValidation = (fieldNumber: number): CreateContractorNameValidation => {
	return Yup.string()
		.nullable()
		.when([`isContractorName${fieldNumber}Disabled`, `contractorNumber${fieldNumber}`], {
			is: (isDisabled: boolean, contractorNumber: string) =>
				!isDisabled && contractorNumber && contractorNumber.trim() !== '',
			then: () =>
				Yup.string()
					.required(`Contractor Lead Name ${fieldNumber} is required.`)
					.test('has-last-name', `Please enter last name for Contractor Lead Name ${fieldNumber}`, function (value) {
						if (value && value.trim() !== '') {
							const nameParts = value.trim().split(' ');
							return nameParts.length > 1;
						}
						return false;
					}),
			otherwise: () =>
				Yup.string()
					.nullable()
					.when(`isContractorName${fieldNumber}Disabled`, {
						is: false,
						then: () =>
							Yup.string()
								.nullable()
								.test(
									'has-last-name',
									`Please enter last name for Contractor Lead Name ${fieldNumber}`,
									function (value) {
										if (value && value.trim() !== '') {
											const nameParts = value.trim().split(' ');
											return nameParts.length > 1;
										}
										return true;
									}
								),
						otherwise: () => Yup.string().notRequired(),
					}),
		});
};
export const dealerVisitProductsValidationSchema = Yup.array()
	.of(
		Yup.object().shape({
			id: Yup.number().required('ID is required'),
			value: Yup.string().required('Value is required'),
		})
	)
	.when('role', {
		is: RoleType.BDE,
		then: (schema) =>
			schema.min(1, 'At least one discussed product is required').required('Discussed products are required'),
		otherwise: (schema) => schema.notRequired(),
	});
export const meetingClubValidationSchema = Yup.array()
	.of(
		Yup.object().shape({
			id: Yup.string().required('ID is required'),
			value: Yup.string().required('Value is required'),
		})
	)
	.when('role', {
		is: RoleType.BDE,
		then: (schema) => schema.min(1, 'At least one club is required').required('Club is required'),
		otherwise: (schema) => schema.notRequired(),
	});
