import { ReactElement, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarConfig } from '@config/sidebar.config';
import { useProfileQuery } from '@services/hooks/profile/useProfileQuery';
import { ProfileData } from '@services/hooks/profile';
import { useGetRxDb } from '@hooks/getRxdbData';
import { onProfileImageError } from '@helper/utils';
import { overlayRemoveFadeAction } from '@components/common/sort';
import { useSiderToggleMenu } from '@store/useSiderToggleMenu';
import { LogoutButton } from '@components/common';
import { MenuItem } from '@components/common/sidebar/menuItem';

export const Sidebar = (): ReactElement => {
	const { isOpen, setModal } = useSiderToggleMenu();

	useProfileQuery();
	const { result: profile } = useGetRxDb<ProfileData>('profile');

	const toggleMenu = useCallback(() => {
		overlayRemoveFadeAction(() => setModal(false), 'sidebar-overlay');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<aside id="sidenav" className={`sidenav ${isOpen ? 'open' : ''}`}>
				<button type="button" aria-label="closebtn" onClick={toggleMenu} value="closebtn" className="closebtn">
					<span className="icon-x-circle"></span>
				</button>
				<div className="user-profile">
					<picture>
						<img
							role="presentation"
							src={profile[0]?.profile_image_c}
							alt="User Profile"
							title="User Profile"
							width="80"
							height="80"
							onError={onProfileImageError}
						/>
					</picture>
					<span>Hello,</span>
					<NavLink to="/profile" className="user-name" aria-label="Edit Profile" onClick={toggleMenu}>
						<span className="h5">{profile[0]?.name}</span>
						<span className="icon-edit" />
					</NavLink>
				</div>
				<nav>
					<ul>
						{SidebarConfig.map((menu) => (
							<MenuItem key={menu.key} menu={menu} toggleMenu={toggleMenu} />
						))}
					</ul>
				</nav>
				{LogoutButton()}
			</aside>
			<div className={`sidebar-overlay ${isOpen ? 'show' : ''}`} id="sidebar-overlay"></div>
		</>
	);
};
