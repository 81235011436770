import { ReactElement } from 'react';
import { CustomTextInput } from '../addEditDealerVisit/customTextInput';
import { activityDiscussionProducts, IdealerVisitFormInitialValues, premiumProducts } from '../addEditDealerVisit';
import { ProductImages } from '@components/siteLeads/components/siteProducts/productImages';
import { MultiselectDropdown } from '@components/common';
import { keyDownEvent } from '@helper/utils';
import { IDelaerVisitBDELevelFieldsProps } from '.';

export const DelaerVisitBDELevelFields = (props: IDelaerVisitBDELevelFieldsProps): ReactElement => {
	const { formik, dealerVisitProducts, handleChangeNumber, handleImageAdd } = props;
	return (
		<>
			<li className="form-control d-col d-col-2">
				<CustomTextInput<IdealerVisitFormInitialValues>
					id="siteLocation"
					label="Site Location"
					formik={formik}
					required
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<CustomTextInput<IdealerVisitFormInitialValues> id="address" label="Address" formik={formik} required />
			</li>

			<li className="form-control d-col d-col-2">
				<CustomTextInput<IdealerVisitFormInitialValues> id="state" label="State" formik={formik} />
			</li>

			<li className="form-control d-col d-col-2">
				<CustomTextInput<IdealerVisitFormInitialValues> id="city" label="City" formik={formik} />
			</li>

			<li className="d-col d-col-2">
				<MultiselectDropdown
					id="premiumProducts"
					name="premiumProducts"
					label="Premium Product Discussed"
					className="input-border"
					onBlur={formik.handleBlur}
					values={formik.values.premiumProducts}
					options={premiumProducts}
					setFieldValue={formik.setFieldValue}
				/>
			</li>

			<li className="d-col d-col-2">
				<MultiselectDropdown
					id="productRSI"
					name="productRSI"
					className="input-border"
					label="Product Discussed for RSI"
					onBlur={formik.handleBlur}
					values={formik.values.productRSI}
					options={dealerVisitProducts}
					setFieldValue={formik.setFieldValue}
					error={formik.touched.productRSI && formik.errors.productRSI ? formik.errors.productRSI.toString() : null}
					required
				/>
			</li>

			<li className="d-col d-col-2">
				<MultiselectDropdown
					id="productCompetitionFighting"
					name="productCompetitionFighting"
					className="input-border signle-drawwer-lable"
					label="Product Discussed for Competition Fighting"
					onBlur={formik.handleBlur}
					values={formik.values.productCompetitionFighting}
					options={dealerVisitProducts}
					setFieldValue={formik.setFieldValue}
				/>
			</li>

			<li className="d-col d-col-2">
				<MultiselectDropdown
					id="activityDiscussed"
					name="activityDiscussed"
					className="input-border"
					label="Activity Discussion"
					onBlur={formik.handleBlur}
					values={formik.values.activityDiscussed}
					options={activityDiscussionProducts}
					setFieldValue={formik.setFieldValue}
					error={
						formik.touched.activityDiscussed && formik.errors.activityDiscussed
							? formik.errors.activityDiscussed.toString()
							: null
					}
					required
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<CustomTextInput<IdealerVisitFormInitialValues>
					id="contractorNumber1"
					label="Contractor Lead Number 1"
					formik={formik}
					maxLength={10}
					onKeyDown={keyDownEvent}
					handleChange={handleChangeNumber}
					dataAttributes={{
						name: 'contractorName1',
						field: 'isContractorName1Disabled',
					}}
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<CustomTextInput<IdealerVisitFormInitialValues>
					id="contractorName1"
					label="Contractor Lead Name 1"
					formik={formik}
					isDisabled={formik.values.isContractorName1Disabled}
					required={!!formik.values.contractorNumber1}
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<CustomTextInput<IdealerVisitFormInitialValues>
					id="contractorNumber2"
					label="Contractor Lead Number 2"
					formik={formik}
					maxLength={10}
					onKeyDown={keyDownEvent}
					handleChange={handleChangeNumber}
					dataAttributes={{
						name: 'contractorName2',
						field: 'isContractorName2Disabled',
					}}
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<CustomTextInput<IdealerVisitFormInitialValues>
					id="contractorName2"
					label="Contractor Lead Name 2"
					formik={formik}
					isDisabled={formik.values.isContractorName2Disabled}
					required={!!formik.values.contractorNumber2}
				/>
			</li>

			<li className="form-control d-col d-col-2">
				<CustomTextInput<IdealerVisitFormInitialValues> id="siteAddress" label="Site Address" formik={formik} />
			</li>

			<li className="d-col d-col-2">
				<MultiselectDropdown
					id="orderTaken"
					name="orderTaken"
					className="input-border"
					label="Order Taken"
					onBlur={formik.handleBlur}
					values={formik.values.orderTaken ?? []}
					options={dealerVisitProducts}
					setFieldValue={formik.setFieldValue}
				/>
			</li>

			{formik.values.orderTaken.length > 0 && (
				<li className="form-control d-col d-col-2">
					<CustomTextInput<IdealerVisitFormInitialValues>
						id="orderTakenVolume"
						label="Order Taken Volume"
						formik={formik}
					/>
				</li>
			)}

			<li className="d-col d-col-2">
				<ProductImages fieldName="productImages" formik={formik} handleAddImage={handleImageAdd} />
			</li>

			<li className="form-control d-col d-col-2">
				<CustomTextInput<IdealerVisitFormInitialValues> id="feedback" label="Feedback" formik={formik} maxLength={50} />
			</li>
		</>
	);
};
