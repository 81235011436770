import { Dropdown } from '@components/common';
import { ReactElement, useEffect } from 'react';
import { IFeedback, IFeedbackExpectation } from '.';
import { exceptionFeedbackQuestions } from '@config/feedbackQuestions';

export const FeedbackExpectation = (props: IFeedbackExpectation): ReactElement => {
	const { formik, exception } = props;

	useEffect(() => {
		if (exception?.length) {
			const updatedQuestions = exception?.map((item) => {
				const key = Object.keys(item)[0];
				const value = item[key];

				return {
					...exceptionFeedbackQuestions[key][0],
					question: value,
				};
			});

			formik.setFieldValue(
				'exception',
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-expect-error
				updatedQuestions.map((ele) => ({
					id: ele.id,
					productId: ele.id,
					feedbackQuestion: ele.question,
					feedbackOptions: ele.options,
					feedbackResponse: '',
					type: 'dropdown',
					placeHolder: ele.placeHolder,
				}))
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exception]);

	return (
		<div className="feedback-exceptions-wrapper">
			<h3 className="list-title">
				<span className="title-wrapper">
					<span className="title-number">3</span>
					<span className="title-name">Feedback On Exceptions</span>
				</span>
			</h3>

			{formik.values.exception?.map((feedback: IFeedback, index: number) => {
				return (
					<div key={feedback.id} className="about-question">
						<span className="h4">{feedback.feedbackQuestion}</span>
						<Dropdown
							id={`exception[${index}].feedbackResponse`}
							name={`exception[${index}].feedbackResponse`}
							onBlur={formik.handleBlur}
							title={formik.values.exception?.[index]?.feedbackResponse ?? ''}
							error={
								formik.touched?.exception?.[index]?.feedbackResponse &&
								formik.errors?.exception?.[index]?.feedbackResponse
									? formik.errors?.exception?.[index]?.feedbackResponse
									: null
							}
							options={feedback.feedbackOptions}
							setFieldValue={formik.setFieldValue}
						/>
					</div>
				);
			})}
		</div>
	);
};
