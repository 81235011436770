import '@assets/styles/pages/one-to-one-meetings.scss';
import { Dropdown, SingleDatePicker, SubmitButton, TextInput } from '@components/common';
import { useFormik } from 'formik';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import {
	formatDateTime,
	IOneToOneMeetingFormInitialValues,
	oneToOneMeetingFormInitialValues,
	typeOfActivitiesList,
} from '.';
import { maxFileSize } from '@config/constant';
import { useGetRxDb } from '@hooks/getRxdbData';
import { TownList } from '@services/hooks/enduser-details';
import { DropdownOptions } from '@components/common/formControl/dropdown';
import { IDealerVisitProducts } from '@components/delaerVisit';
import { ProductImages } from '@components/siteLeads/components/siteProducts/productImages';
import { ToastType, useTosterStore } from '@store/toster';
import { Modal } from '@components/common/modal/modal';
import { QuickAddEndUser } from '../quickAddEndUser';
import { TimePicker } from '@components/common/time-picker/time-picker';
import { useSetMainHeader } from '@hooks/useSetMainHeader';
import { useValidation } from '@hooks/validations';
import { useFormError } from '@hooks/useFormError';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	useGetOneToOneMeetingsList,
	useOneToOneMeetingMutation,
	useRetrieveMeetingDetails,
} from '@services/hooks/oneToOneMeetings';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { IEndUserList, IOneToOneMeetingsList, IWSSTerritoryList } from '@components/onetoonemeetings';
import {
	convertTime,
	extractTime,
	formatDate,
	formatDateString,
	generateTimeOptions,
	getTownInfo,
} from '@helper/utils';
import { useGeoLocationDetails } from '@hooks/useGeoLocationDetails';
import { usePresignedURLQuery } from '@services/hooks/enduser-details/usePresignedURLQuery';
import { useUploadSiteImagesMutation } from '@services/hooks/site-leads/useUploadSiteImagesMutation';
import { convertUrlToFile } from '@components/delaerVisit/components/addEditDealerVisit';
import { useImageUpload } from '@hooks/useImageUpload';

export const AddEditOneToOneMeetings = (): ReactElement => {
	useSetMainHeader('One To One Connect');
	const { state } = useLocation();
	const navigate = useNavigate();
	const { setToaster } = useTosterStore();
	const { OneToOneMeetingValidationSchema } = useValidation();
	const [isQuickEndModalOpen, setIsQuickEndModalOpen] = useState(false);
	const { result: towns } = useGetRxDb<TownList>('towns');
	const { result: visitProducts } = useGetRxDb<IDealerVisitProducts>('dealer_visit_products');
	const { result: endUserList } = useGetRxDb<IEndUserList>('end_user_list');
	const { result: wssTerritoryList } = useGetRxDb<IWSSTerritoryList>('wss_territory_list');
	const { refetch: refetchOneToOneMeetingList } = useGetOneToOneMeetingsList();
	const { coords } = useGeoLocationDetails();
	const { handleAddImage } = useImageUpload();
	const {
		data: retrievedMeetingDetails,
		isFetching: isFetchingMeeting,
		error: errorFetchingMeeting,
	} = useRetrieveMeetingDetails(state?.meetingId);
	const { mutate: createUpdateOneToOneMeeting, isPending: isMeetingPending } = useOneToOneMeetingMutation(
		state?.meetingId
	);
	const { mutate: mutatePresignedURL, data: getSiteImagesPresignedUrl } = usePresignedURLQuery();
	const { mutate: putSiteImagesData, isPending: isPutSiteImagePending } =
		useUploadSiteImagesMutation(getSiteImagesPresignedUrl);

	const formik = useFormik({
		initialValues: oneToOneMeetingFormInitialValues,
		onSubmit: () => {},
		validationSchema: OneToOneMeetingValidationSchema,
	});
	const getErrorMessage = useFormError<IOneToOneMeetingFormInitialValues>(formik);

	const townsList = useMemo((): DropdownOptions[] => {
		if (towns?.length) {
			return towns.map((t) => ({
				id: t?.id,
				value: `${t?.towncode_c} - ${t?.towndescription_c}`,
			}));
		}
		return [];
	}, [towns]);

	const productsList = useMemo((): DropdownOptions[] => {
		if (visitProducts?.length) {
			return [...visitProducts]
				.sort((a, b) => a.productgroup3description_c.localeCompare(b.productgroup3description_c))
				.map((d, index) => ({
					id: index,
					value: d.productgroup3description_c,
					name: d.prdgroup3_c,
				}));
		}
		return [];
	}, [visitProducts]);

	const endUsers = useMemo((): DropdownOptions[] => {
		if (endUserList?.length) {
			return endUserList.map((d) => ({
				id: d?.id,
				value: `${d?.name} - ${d?.phone}`,
			}));
		}
		return [];
	}, [endUserList]);

	const wssTerritory = useMemo((): DropdownOptions[] => {
		if (wssTerritoryList?.length) {
			return wssTerritoryList.map((d, index) => ({
				id: index,
				value: `${d?.wssterritorycode_c} - ${d?.wssterritoryname_c}`,
				name: d?.wssterritoryname_c,
			}));
		}
		return [];
	}, [wssTerritoryList]);

	const loadImages = async (event_image_1?: string | null, event_image_2?: string | null): Promise<void> => {
		const imageUrls = [];
		if (event_image_1) imageUrls.push(event_image_1);
		if (event_image_2) imageUrls.push(event_image_2);

		const promises = imageUrls.map((url) => convertUrlToFile(url));
		const files = await Promise.all(promises);
		if (files.length > 0) {
			formik.setFieldValue('productImages', files);
		}
	};

	const handleImageAdd = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const maxImageLimit = 2;
			const folderName = 'one_to_one_meetings';
			const result = handleAddImage(event, formik.values.productImages, maxFileSize, maxImageLimit, folderName);
			if (result) {
				const { payload, updatedImages } = result;
				updatedImages && formik.setFieldValue('productImages', updatedImages);
				payload && mutatePresignedURL?.(payload);
			}
		},
		[formik, handleAddImage, mutatePresignedURL]
	);

	const handleQuickEndUser = (): void => {
		setIsQuickEndModalOpen(true);
	};

	const handleSubmit = useCallback((): void => {
		const townInfo = getTownInfo(towns, formik.values.town);
		const payload: IOneToOneMeetingsList = {
			town_code_c: townInfo?.towncode ?? '',
			town_name_c: townInfo?.townname ?? '',
			mobile_no_c: formik.values.endUser?.split('-')[1]?.trim(),
			wss_teritorry_code_c: formik.values.wssTerritory.split('-')[0]?.trim(),
			wss_teritorry_name_c: wssTerritory?.find((p) => p.value.includes(formik.values.wssTerritory))?.name,
			product1_demoed_code_c: productsList?.find((p) => p.value.includes(formik.values.Product1Demo))?.name,
			product1_demoed_c: formik.values.Product1Demo,
			product2_demoed_code_c: productsList?.find((p) => p.value.includes(formik.values.Product2Demo))?.name,
			product2_demoed_c: formik.values.Product2Demo,
			activities_done_at_site_c: formik.values.typeOfActivity,
			other_suggestions_c: formik.values.typeOfActivity === 'Others' ? formik.values.otherTypeOfActivity : null,
			user_name_c: formik.values.endUser?.split('-')[0]?.trim(),
			startdatetime: formatDateTime(
				`${formatDate(new Date(formik.values.meetingDate))}${' '}
				${
					generateTimeOptions()
						.find((item) => item.value === formik.values.meetingTime)
						?.id.toString() ?? ''
				}`
			),
			subject: 'One to One Meeting',
			event_image_1: formik.values.productImages[0]
				? getSiteImagesPresignedUrl?.[0]?.normal_url ?? retrievedMeetingDetails?.event_image_1
				: null,
			event_image_2: formik.values.productImages[1]
				? getSiteImagesPresignedUrl?.[1]?.normal_url ?? retrievedMeetingDetails?.event_image_2
				: null,
			longitude: coords.longitude,
			latitude: coords.latitude,
		};

		putSiteImagesData?.(formik.values.productImages, {
			onSuccess: () => {
				createUpdateOneToOneMeeting?.(payload, {
					onSuccess: () => {
						setToaster(
							true,
							ToastType.success,
							`One to one meeting ${state?.meetingId ? 'updated' : 'created'} successfully`
						);
						refetchOneToOneMeetingList?.();
						navigate('/one-to-one-meeting-summary', {
							replace: true,
						});
					},
					onError: (error) => {
						setToaster(true, ToastType.error, error?.message ?? 'Failed to create One to one meeting!');
					},
				});
			},
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		formik.values,
		getSiteImagesPresignedUrl,
		wssTerritory,
		productsList,
		coords,
		createUpdateOneToOneMeeting,
		state,
		refetchOneToOneMeetingList,
		putSiteImagesData,
	]);

	useEffect(() => {
		if (state?.meetingId && retrievedMeetingDetails) {
			const { event_image_1, event_image_2 } = retrievedMeetingDetails;
			loadImages(event_image_1, event_image_2);
			formik.setValues(
				{
					...formik.values,
					endUser: `${retrievedMeetingDetails?.user_name_c} - ${retrievedMeetingDetails?.mobile_no_c}`,
					wssTerritory:
						retrievedMeetingDetails?.wss_teritorry_code_c && retrievedMeetingDetails?.wss_teritorry_name_c
							? `${retrievedMeetingDetails?.wss_teritorry_code_c} - ${retrievedMeetingDetails?.wss_teritorry_name_c}`
							: '',
					Product1Demo: retrievedMeetingDetails?.product1_demoed_c ?? '',
					Product2Demo: retrievedMeetingDetails?.product2_demoed_c ?? '',
					town:
						retrievedMeetingDetails?.town_code_c && retrievedMeetingDetails?.town_name_c
							? `${retrievedMeetingDetails?.town_code_c} - ${retrievedMeetingDetails?.town_name_c}`
							: '',
					typeOfActivity: retrievedMeetingDetails?.activities_done_at_site_c,
					otherTypeOfActivity: retrievedMeetingDetails?.other_suggestions_c,
					meetingDate: retrievedMeetingDetails?.startdatetime
						? formatDateString(retrievedMeetingDetails.startdatetime, false)
						: '',
					meetingTime: retrievedMeetingDetails?.startdatetime
						? convertTime(extractTime(retrievedMeetingDetails?.startdatetime))
						: '',
				},
				true
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state?.meetingId, retrievedMeetingDetails]);

	return (
		<>
			<section className="add-one-to-one-meeting">
				<Wrapper isLoaderVisible={isFetchingMeeting} isError={errorFetchingMeeting}>
					<div className="container">
						<form>
							<ul className="row">
								<li className="form-control d-col d-col-2">
									<TextInput
										id="oneToOneConnect"
										name="oneToOneConnect"
										type="text"
										onBlur={formik.handleBlur}
										value={formik.values.oneToOneConnect}
										label="One To One Connect"
										setFieldValue={formik.setFieldValue}
										error={getErrorMessage('oneToOneConnect')}
										disabled
									/>
								</li>
								<li className="d-col d-col-2 form-control">
									<Dropdown
										id="endUser"
										name="endUser"
										label="End User"
										onBlur={formik.handleBlur}
										title={formik.values.endUser ?? ''}
										error={getErrorMessage('endUser')}
										options={endUsers}
										setFieldValue={formik.setFieldValue}
										blurValidate
										required
									/>
								</li>
								<li className="d-col d-col-2">
									<Dropdown
										id="wssTerritory"
										name="wssTerritory"
										label="WSS Territory"
										onBlur={formik.handleBlur}
										title={formik.values.wssTerritory ?? ''}
										error={getErrorMessage('wssTerritory')}
										options={wssTerritory}
										setFieldValue={formik.setFieldValue}
										blurValidate
									/>
								</li>
								<li className="d-col d-col-2">
									<Dropdown
										id="town"
										name="town"
										label="Town"
										onBlur={formik.handleBlur}
										title={formik.values.town ?? ''}
										error={getErrorMessage('town')}
										options={townsList}
										setFieldValue={formik.setFieldValue}
										blurValidate
									/>
								</li>
								<li className="d-col d-col-2">
									<SingleDatePicker
										className="single-date-picker"
										id="meetingDate"
										name="meetingDate"
										value={formik.values.meetingDate}
										label="Meeting Date"
										formik={formik}
										error={getErrorMessage('meetingDate')}
										required
										maxDate={new Date()}
									/>
								</li>
								<li className="d-col d-col-2">
									<TimePicker
										name="meetingTime"
										id="meetingTime"
										label="Meeting Time"
										value={formik.values.meetingTime}
										formik={formik}
										icon={'icon-clock'}
										error={getErrorMessage('meetingTime')}
										required
									/>
								</li>
								<li className="d-col d-col-2">
									<Dropdown
										id="typeOfActivity"
										name="typeOfActivity"
										label="Type of Activity"
										onBlur={formik.handleBlur}
										title={formik.values.typeOfActivity ?? ''}
										error={getErrorMessage('typeOfActivity')}
										options={typeOfActivitiesList}
										setFieldValue={formik.setFieldValue}
										blurValidate
										required
									/>
								</li>
								{formik.values.typeOfActivity === 'Others' && (
									<li className="form-control d-col d-col-2">
										<TextInput
											id="otherTypeOfActivity"
											name="otherTypeOfActivity"
											type="text"
											onBlur={formik.handleBlur}
											value={formik.values.otherTypeOfActivity ?? ''}
											label="Other Type of Activity"
											setFieldValue={formik.setFieldValue}
											error={getErrorMessage('otherTypeOfActivity')}
											required
										/>
									</li>
								)}
								<li className="d-col d-col-2">
									<Dropdown
										id="Product1Demo"
										name="Product1Demo"
										label="Product 1 Demo"
										onBlur={formik.handleBlur}
										title={formik.values.Product1Demo ?? ''}
										error={getErrorMessage('Product1Demo')}
										options={productsList}
										setFieldValue={formik.setFieldValue}
										blurValidate
										required
									/>
								</li>
								<li className="d-col d-col-2">
									<Dropdown
										id="Product2Demo"
										name="Product2Demo"
										label="Product 2 Demo"
										onBlur={formik.handleBlur}
										title={formik.values.Product2Demo ?? ''}
										error={getErrorMessage('Product2Demo')}
										options={productsList}
										setFieldValue={formik.setFieldValue}
										blurValidate
									/>
								</li>
								<li className="d-col d-col-2">
									<ProductImages fieldName="productImages" formik={formik} handleAddImage={handleImageAdd} required />
								</li>
								<li className="d-col d-col-2">
									<div className={`manage-participants-button`}>
										<div className="label">
											<span className="content">Referral Leads</span>
										</div>
										<div className="location-wrapper">
											<div className="location-icons">
												<button
													className="location-btn"
													aria-label="location map link"
													type="button"
													onClick={handleQuickEndUser}
												>
													<span className="icon-plus"></span>
												</button>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</form>
						<SubmitButton
							label="Submit"
							isDisabled={
								!formik.isValid || !formik.dirty || isPutSiteImagePending || !formik.values.productImages.length
							}
							isPending={isMeetingPending || isPutSiteImagePending}
							handleSubmitClick={handleSubmit}
						/>
					</div>
				</Wrapper>
			</section>
			<Modal
				modalId="quickAddEndUser"
				className="dialog dialog-lg  dialog-bottom qr-scan-wrapper quick-add-user-pop"
				modalOpen={isQuickEndModalOpen}
				onModalClose={() => {
					setIsQuickEndModalOpen(false);
				}}
			>
				<QuickAddEndUser setIsQuickEndModalOpen={setIsQuickEndModalOpen} />
			</Modal>
		</>
	);
};
