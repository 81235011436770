import { memo, ReactElement, useMemo, useState } from 'react';
import { IDealerDropdownProps } from '@components/common/formControl/dealer-dropdown/index';
import { useDealerListRxDb } from '@services/hooks/enduser-details/rxdbHooks/useDealerListRxDb';
import { useDebounce } from '@hooks/useDebounce';
import { DealersList } from '@components/endUserDetails/components/moreDetailsForm';
import { Dropdown } from '@components/common';

const DealerDropdown = <T extends { dealerName: string }>(props: IDealerDropdownProps<T>): ReactElement => {
	const { id, name, label, disabled, placeholder = '', onBlur, setFieldValue, title, error } = props;
	const [dealerSearch, setDealerSearch] = useState<string>('');
	const { result: dealerList } = useDealerListRxDb({
		searchParams: [
			{ key: 'dealername_c', value: dealerSearch },
			{ key: 'dealercode_c', value: dealerSearch },
		],
		sortBy: 'dealername_c',
	});

	const onDropDownSearch = useDebounce((e: React.KeyboardEvent<HTMLInputElement>): void => {
		const element = e.target as HTMLInputElement;
		setDealerSearch(element.value);
	}, 300);

	const dealersList = useMemo((): DealersList[] => {
		if (dealerList?.length) {
			return dealerList.map((d, index) => ({
				id: index,
				value: `${d._data?.dealercode_c} -  ${d._data?.dealername_c}`,
				name: d._data?.dealername_c,
			}));
		}
		return [];
	}, [dealerList]);

	return (
		<Dropdown
			id={id}
			name={name}
			label={label}
			onBlur={onBlur}
			title={title}
			error={error}
			options={dealersList}
			setFieldValue={setFieldValue}
			disabled={disabled}
			handelKeyDownProp={onDropDownSearch}
			listFetching={true}
			placeholder={placeholder}
		/>
	);
};

export default memo(DealerDropdown);
