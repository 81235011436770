import * as Yup from 'yup';
import { get18YearsAgo } from '@helper/utils';

export interface ICommonValidationFields {
	first_name: Yup.StringSchema<string | null | undefined>;
	middle_name: Yup.StringSchema<string | null | undefined>;
	last_name: Yup.StringSchema<string | null | undefined>;
	religion: Yup.StringSchema<string | null | undefined>;
	language: Yup.StringSchema<string | null | undefined>;
	email: Yup.StringSchema<string | null | undefined>;
	birth_date: Yup.DateSchema<Date | null | undefined>;
}

const useCommanValidationFields = (): ICommonValidationFields => {
	const first_name = Yup.string()
		.required('First Name is required')
		.matches(/^(?!\s)(?=.*[a-zA-Z])[a-zA-Z\s.]+$/, 'Only English characters are allowed in the first name')
		.nullable();
	const middle_name = Yup.string()
		.matches(/^(?!\s)(?=.*[a-zA-Z])[a-zA-Z\s.]+$/, 'Only English characters are allowed in the middle name')
		.nullable();
	const last_name = Yup.string()
		.required('Last Name is required')
		.matches(/^(?!\s)(?=.*[a-zA-Z])[a-zA-Z\s.]+$/, 'Only English characters are allowed in the last name')
		.nullable()
		.max(40, 'Must be less then or equal 40 characters');
	const religion = Yup.string().required('Religion is required').nullable();
	const language = Yup.string().required('Language is required').nullable();
	const email = Yup.string().email('Invalid email address').nullable();
	const birth_date = Yup.date()
		.max(get18YearsAgo(), 'Must be at least 18 years old.')
		.required('Birth date is required');

	return {
		first_name,
		middle_name,
		last_name,
		religion,
		language,
		birth_date,
		email,
	};
};

export default useCommanValidationFields;
