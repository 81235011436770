import { memo, ReactElement, useCallback, useEffect, useRef } from 'react';
import { ITextInput } from '.';
import { useScrollIntoView } from '@hooks/usescrollIntoView';

const TextInput = (props: ITextInput): ReactElement => {
	const {
		id,
		type,
		name,
		value,
		label,
		error,
		required,
		disabled,
		setFieldValue,
		onBlur,
		maxLength,
		onKeyDown,
		className,
		placeholder,
		isAutoFocus,
		onChange,
		dataAttributes,
		labelClass,
	} = props;
	const inputRef = useRef<HTMLInputElement>(null);
	const { handleFocus } = useScrollIntoView(inputRef);

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>): void => {
			name && setFieldValue && setFieldValue(name, e.target.value.replace(/^\s+/g, ''));

			if (onChange && name) {
				onChange(e);
			}
		},
		[name, onChange, setFieldValue]
	);

	useEffect(() => {
		if (isAutoFocus && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isAutoFocus]);

	const dataProps: { [key: string]: string } = {};
	if (dataAttributes) {
		Object.keys(dataAttributes).forEach((key) => {
			dataProps[`data-${key}`] = dataAttributes[key];
		});
	}

	return (
		<>
			<label htmlFor={id} className={`${required ? 'required' : ''} ${labelClass ?? ''}`}>
				{label}
			</label>
			<input
				id={id}
				name={name}
				type={type}
				value={value}
				ref={inputRef}
				onBlur={onBlur}
				onFocus={handleFocus}
				onChange={handleChange}
				disabled={disabled}
				autoComplete="off"
				maxLength={maxLength}
				onKeyDown={onKeyDown}
				className={className}
				placeholder={placeholder}
				{...dataProps}
			/>
			{error && <p className={`error-message ${error && 'show'}`}>{error}</p>}
		</>
	);
};

export default memo(TextInput);
