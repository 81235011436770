import * as XLSX from 'xlsx';
import { DataRow } from '@helper/utils';

const camelToTitleCase = (str: string): string => {
	const result = str.replace(/([A-Z])/g, ' $1');
	return result
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
};

export const ExportXlsxFile = (data: DataRow[], fileName = 'attendance_data'): void => {
	if (data.length === 0) {
		return;
	}

	const workbook = XLSX.utils.book_new();

	const headers = Object.keys(data[0]);
	const customHeaders = headers.map(camelToTitleCase);
	const wsData = [customHeaders, ...data.map((row) => headers.map((header) => row[header]))];

	const worksheet = XLSX.utils.aoa_to_sheet(wsData);
	XLSX.utils.sheet_add_aoa(worksheet, [customHeaders], { origin: 'A1' });

	XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

	const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
	const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

	const urlLink = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = urlLink;
	link.setAttribute('download', `${fileName}_${new Date().toISOString()}.xlsx`);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	URL.revokeObjectURL(urlLink);
};

// TODO:: Its keep as it s for future use.
export const ArrayToCSV = (data: DataRow[], fileName = 'attendance_data'): void => {
	if (data.length === 0) {
		return;
	}

	const headers: string[] = Object.keys(data[0]);
	const csvRows: string[] = [];

	csvRows.push(headers.join(','));

	for (const row of data) {
		const values: string[] = headers.map((header) => {
			const escaped: string = ('' + row[header]).replace(/"/g, '\\"');
			return `"${escaped}"`;
		});
		csvRows.push(values.join(','));
	}

	const csv: string = csvRows.join('\n');
	const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
	const url = URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', `${fileName}_${new Date().toISOString()}.csv`);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	URL.revokeObjectURL(url);
};
