import { MeetingTitleInfo } from '@components/meeting-details/component/meetingTitleInfo';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import barcodePng from '@assets/images/barcode.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetMeetingQuery } from '@services/hooks/meetings/useGetMeetingQuery';
import { IMeetingDetails } from '@components/meeting-details';
import { Modal } from '@components/common/modal/modal';
import { AddQuickUserModal } from './components/addQuickUser/addQuickUserForm';
import TextInput from '@components/common/formControl/textInput/textInput';
import { defaultInitialFeedback } from '.';
import { useFormik } from 'formik';
import { useAddFeedbackMutation } from '@services/hooks/meetings/useUpdateFeedbackForm';
import { ToastType, useTosterStore } from '@store/toster';
import { useGetParticipantsQuery } from '@services/hooks/meetings/useGetParticipants';
import { Wrapper } from '@components/common/Wrapper/wrapper';
import { SubmitButton } from '@components/common';
import { SummaryBoxCard } from './components/summaryCard/summaryBoxCard';
import { useMeetingsListQuery } from '@services/hooks/meetings/useMeetingsListQuery';
import { useUpdateMeetingStatus } from '@services/hooks/meetings/useUpdateMeetingStatus';
import { getDateByRange } from '@helper/utils';
import { MeetingQrModal } from './components/meetingsQrModal/meetingQrModal';
import Checkbox from '@components/common/formControl/checkbox/checkbox';
import { useValidation } from '@hooks/validations';
import { useComponentLevelAccessCheck } from '../../authGuard/hooks/useComponentLevelAccessCheck';

export const TodayMeetingDetails = (): ReactElement => {
	const { state } = useLocation();
	const { data, isFetching, error } = useGetMeetingQuery(state?.id);
	const { data: participants, error: participantsError, refetch } = useGetParticipantsQuery({ id: state?.id });
	const [feedbackCheck, setFeedbackCheck] = useState(false);
	const navigate = useNavigate();
	const { refetch: meetingsRefetch } = useMeetingsListQuery();

	const commonProps: IMeetingDetails = {
		data: data,
		isFetching,
		error,
	};

	const { setToaster } = useTosterStore();
	const { FeedbackValidationSchema } = useValidation();
	const [showQuickAddUser, setShowQuickAddUser] = useState(false);
	const { mutate, isPending } = useAddFeedbackMutation(state?.id);
	const { mutate: meetingStatusUpdate, isPending: meetingsUpdatePending } = useUpdateMeetingStatus(state?.id);
	const [showQrCode, setShowQrCode] = useState<boolean>(false);

	const invitedParticipants = participants?.filter((item) => !item.non_invited_c && !item.attendance_c) ?? [];
	const attendedNon = participants?.filter((item) => item.non_invited_c && item.attendance_c) ?? [];
	const attendedInv = participants?.filter((item) => !item.non_invited_c && item.attendance_c) ?? [];
	const checkImrTodayMeetingPermission = useComponentLevelAccessCheck('todays-meeting');

	const formik = useFormik({
		initialValues: defaultInitialFeedback,
		onSubmit: () => {
			handleSubmitForm();
		},
		validationSchema: FeedbackValidationSchema,
	});

	useEffect(() => {
		const intervalId = setInterval(() => {
			refetch?.();
		}, 30000); // Fetch participants for every 30 sec

		return (): void => clearInterval(intervalId);
	}, [refetch]);

	const handleSubmitForm = useCallback(() => {
		if (attendedInv.length === 0 && attendedNon.length === 0) {
			setToaster(true, ToastType.error, 'Atleast one attendee should be in a meeting!');
			return;
		}
		const payload = {
			obstaclesfaced_c: formik.values.obstacleFaced.trim(),
			learningfromactivities_c: formik.values.learningForm.trim(),
		};
		mutate(payload, {
			onSuccess: () => {
				meetingStatusUpdate(
					{ status: 'List Of Meeting Conducted' },
					{
						onSuccess: () => {
							formik.resetForm();
							navigate('/meetings/all-meetings');
							setToaster(true, ToastType.success, 'Meeting Added Successfully');
							meetingsRefetch?.();
						},
						onError: (error) => {
							setToaster(true, ToastType.error, error.message);
						},
					}
				);
			},
			onError: (error) => {
				setToaster(true, ToastType.error, error.message);
			},
		});
	}, [
		attendedInv.length,
		attendedNon.length,
		formik,
		mutate,
		setToaster,
		meetingStatusUpdate,
		navigate,
		meetingsRefetch,
	]);

	return (
		<>
			<Wrapper isError={error} isLoaderVisible={isFetching}>
				<section className="todays-meeting-section">
					<div className="top-content">
						<div className="container">
							<MeetingTitleInfo {...commonProps} />

							<div className="divider"></div>
							<ul className="mised-call-date-list">
								<li>
									<div className="call-history-wrapper">
										<span className="content h4">Missed Call Number</span>
										<span className="number-date">{data?.missedcallnumber_c ?? '--'}</span>
									</div>
								</li>

								<li>
									<div className="call-history-wrapper">
										<span className="content h4">Missed Date Time</span>
										<span className="number-date">{data?.start_time_c ?? '--'}</span>
									</div>
								</li>
							</ul>

							<div className="qr-box">
								<span className="h4">Scan QR Code</span>
								<a
									href="/"
									aria-label="qr scan"
									className="qr-scan"
									onClick={(e) => {
										e.preventDefault();
										setShowQrCode(true);
									}}
								>
									<img src={barcodePng} alt="barcode" title="barcode" width="16" height="16" />
								</a>
							</div>
						</div>
					</div>

					<Wrapper isError={participantsError}>
						<div className="container">
							<div className="participant-card">
								<SummaryBoxCard
									cardKey={0}
									label="Invited"
									value={invitedParticipants?.length}
									path="/today-meetings/attendance-details"
									participants={participants ?? []}
									id={state?.id}
									placeHolder="Non-Attended"
								/>
								<SummaryBoxCard
									cardKey={1}
									label="Attended"
									value={attendedNon?.length}
									path="/today-meetings/attendance-details"
									participants={participants ?? []}
									id={state?.id}
									placeHolder="Non-Invited"
								/>
								<SummaryBoxCard
									cardKey={2}
									label="Attended"
									value={attendedInv?.length}
									path="/today-meetings/attendance-details"
									participants={participants ?? []}
									id={state?.id}
									placeHolder="Invited"
								/>
							</div>

							{checkImrTodayMeetingPermission && (
								<div className="add-part-wrapper">
									<button
										type="button"
										aria-label="Add Attended (Non-Invited)"
										className="add-part-btn"
										onClick={() => setShowQuickAddUser(true)}
									>
										<span className="icon">
											<span className="icon-plus"></span>
										</span>
										<span className="content">
											Add Attended <span className="normal-text">(Non-Invited)</span>
										</span>
									</button>
								</div>
							)}

							<div className="cmn-heading">
								<span> Contractor Feedback </span>
							</div>

							<div className="">
								<form onSubmit={formik.handleSubmit}>
									<ul className="feedback-form row">
										<li className="form-control two-line-lable sm-single d-col d-col-2">
											<TextInput
												id="obstacleFaced"
												name="obstacleFaced"
												type="text"
												value={formik.values.obstacleFaced ?? ''}
												label="Obstacle faced in meeting arrangement /execution"
												placeholder="Enter Here"
												setFieldValue={formik.setFieldValue}
												error={
													formik.touched.obstacleFaced && formik.errors.obstacleFaced
														? formik.errors.obstacleFaced
														: null
												}
												onBlur={formik.handleBlur}
												required
												disabled={!checkImrTodayMeetingPermission}
											/>
										</li>

										<li className="form-control d-col d-col-2">
											<TextInput
												id="learningForm"
												name="learningForm"
												type="text"
												value={formik.values.learningForm ?? ''}
												label="Any learning from the meeting"
												placeholder="Enter Here"
												setFieldValue={formik.setFieldValue}
												onBlur={formik.handleBlur}
												error={
													formik.touched.learningForm && formik.errors.learningForm ? formik.errors.learningForm : null
												}
												required
												disabled={!checkImrTodayMeetingPermission}
											/>
										</li>
									</ul>
								</form>
								{checkImrTodayMeetingPermission && (
									<div className="feedback-about-content">
										<div className="feedback-content">
											<p>
												Click link to fill form
												<a
													aria-label="feedback-form"
													href="https://forms.office.com/r/9sfrn3RqYZ"
													target="_blank"
													rel="noreferrer"
												>
													- User Feedback Structured Questionnaire form
												</a>
											</p>
										</div>
										<Checkbox
											id="terms_and_conditions"
											name="terms_and_conditions"
											checked={feedbackCheck}
											onChange={() => {
												setFeedbackCheck(!feedbackCheck);
											}}
											type="checkbox"
											label="I have filled the Structured questionnaire form"
										/>
									</div>
								)}

								{checkImrTodayMeetingPermission && (
									<SubmitButton
										isEditing={false}
										isDisabled={!feedbackCheck || !formik.isValid || !formik.dirty}
										handleSubmitClick={formik.handleSubmit}
										isPending={isFetching || isPending || meetingsUpdatePending}
										label="Confirm Meeting Conducted"
									>
										<p className="note-content">
											Note: Please add meeting participants before{' '}
											{data?.enddate ? getDateByRange(new Date(data?.enddate), 5) : new Date().toLocaleDateString()}
										</p>
									</SubmitButton>
								)}
							</div>
						</div>
					</Wrapper>
				</section>
			</Wrapper>

			<Modal
				modalId="popupDialog"
				className="dialog dialog-lg  dialog-bottom qr-scan-wrapper quick-add-user-pop"
				modalOpen={showQuickAddUser}
				onModalClose={() => setShowQuickAddUser(false)}
			>
				<AddQuickUserModal
					dateObject={{ id: state?.id }}
					onClose={() => setShowQuickAddUser(false)}
					refetch={() => refetch?.()}
				/>
			</Modal>
			<Modal
				modalId="metingModal"
				className="dialog dialog-lg dialog-bottom scan-popup"
				modalOpen={showQrCode}
				onModalClose={() => {
					setShowQrCode(false);
				}}
			>
				<MeetingQrModal
					onModalClose={() => {
						setShowQrCode(false);
					}}
					dataObject={data}
				/>
			</Modal>
		</>
	);
};
